// FeedbackComponent.js
import React, { useState } from 'react';
import './FeedbackComponent.css'; // We'll create this CSS file for styling

function FeedbackComponent() {
  const [feedback, setFeedback] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    setFeedback({
      ...feedback,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log('Feedback submitted:', feedback);
    setSubmitted(true);
    // Reset the form
    setFeedback({
      name: '',
      email: '',
      message: '',
    });
  };

  return (
    <div className="feedback-container">
      <h1>We Value Your Feedback</h1>
      {submitted && (
        <div className="thank-you-message">
          Thank you for your feedback!
        </div>
      )}
      <form className="feedback-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name<span>*</span></label>
          <input
            type="text"
            id="name"
            name="name"
            value={feedback.name}
            onChange={handleChange}
            required
            placeholder="Enter your name"
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email<span>*</span></label>
          <input
            type="email"
            id="email"
            name="email"
            value={feedback.email}
            onChange={handleChange}
            required
            placeholder="Enter your email"
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Message<span>*</span></label>
          <textarea
            id="message"
            name="message"
            value={feedback.message}
            onChange={handleChange}
            required
            placeholder="Your feedback..."
          />
        </div>
        <button type="submit" className="submit-button">
          Submit Feedback
        </button>
      </form>
    </div>
  );
}

export default FeedbackComponent;

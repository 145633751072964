// src/AuthContext.js

import React, { createContext, useContext, useEffect, useState, useRef } from 'react';
import { supabase } from './supabaseClient';

// Create the AuthContext
const AuthContext = createContext();

// Custom hook to use the AuthContext
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

// AuthProvider component
export const AuthProvider = ({ children }) => {
  // State variables
  const [user, setUser] = useState(null);
  const [role, setRole] = useState(null);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const isFetchingRole = useRef(false);

  useEffect(() => {
    // Determine the environment
    const isDevelopment = process.env.NODE_ENV === 'development';
    const disableAuth = process.env.REACT_APP_DISABLE_AUTH === 'true';
    const shouldDisableAuth = isDevelopment || disableAuth;

    console.log(
      'Environment:',
      process.env.NODE_ENV,
      'isDevelopment:',
      isDevelopment,
      'disableAuth:',
      disableAuth,
      'shouldDisableAuth:',
      shouldDisableAuth
    );

    // Initialize authentication
    const initializeAuth = async () => {
      if (shouldDisableAuth) {
        // Mock user and role for development or when disableAuth is true
        const mockUser = {
          id: 'dev-id',
          user_metadata: { full_name: 'Dev User' },
          email: 'dev@local.com',
        };
        setUser(mockUser);
        setRole('admin'); // You can change this to any role you prefer
        setToken('mock-token'); // Optional: Mock token if needed
        console.log('Mock user set:', mockUser);
        setLoading(false);
        return;
      }

      // Normal authentication flow for production
      try {
        const {
          data: { session },
          error: sessionError,
        } = await supabase.auth.getSession();

        if (sessionError) {
          console.error('Session Error:', sessionError);
          setLoading(false);
          return;
        }

        setUser(session?.user ?? null);

        if (session?.user) {
          setToken(session.access_token);
          await fetchUserRole(session.user.id);
        } else {
          setRole(null);
          setToken(null);
        }
      } catch (error) {
        console.error('Unexpected error during authentication initialization:', error.message);
      } finally {
        setLoading(false);
      }
    };

    initializeAuth();

    if (!shouldDisableAuth) {
      // Set up authentication state change listener only if auth is enabled
      const { data: listener } = supabase.auth.onAuthStateChange(
        async (event, session) => {
          console.log('Auth state changed:', event, session);
          setUser(session?.user ?? null);

          if (session?.user) {
            setToken(session.access_token);
            await fetchUserRole(session.user.id);
          } else {
            setRole(null);
            setToken(null);
          }
        }
      );

      // Cleanup the listener on unmount
      return () => {
        listener.subscription.unsubscribe();
      };
    }

    // If auth is disabled, do not set up the listener
  }, []); // Empty dependency array since environment variables are static during build

  // Function to fetch user role from Supabase
  const fetchUserRole = async (userId) => {
    if (isFetchingRole.current) return;

    isFetchingRole.current = true;

    const cachedRole = localStorage.getItem(`user_role_${userId}`);
    if (cachedRole) {
      setRole(cachedRole);
      isFetchingRole.current = false;
      setLoading(false);
      return;
    }

    try {
      const { data, error } = await supabase
        .from('users')
        .select('role')
        .eq('id', userId)
        .single();

      if (error && error.code !== 'PGRST116') {
        console.error('Error fetching user role:', error);
        setRole(null);
        return;
      }

      if (data && data.role) {
        setRole(data.role);
        localStorage.setItem(`user_role_${userId}`, data.role);
      } else {
        setRole(null);
      }
    } catch (error) {
      console.error('Unexpected error fetching user role:', error.message);
      setRole(null);
    } finally {
      setLoading(false);
      isFetchingRole.current = false;
    }
  };

  // Authentication functions
  const signInWithGoogle = () => {
    supabase.auth.signInWithOAuth({ provider: 'google' });
  };

  const signInWithMicrosoft = () => {
    supabase
      .auth.signInWithOAuth({
        provider: 'azure',
        options: {
          scopes: 'openid profile email User.Read',
        },
      })
      .catch((error) => console.error('Error signing in with Microsoft:', error.message));
  };

  const signUpWithEmail = async (email, password) => {
    try {
      const { error } = await supabase.auth.signUp({ email, password });
      if (error) throw error;
      return { success: true };
    } catch (error) {
      return { success: false, message: error.error_description || error.message };
    }
  };

  const signInWithEmail = async (email, password) => {
    try {
      const { error } = await supabase.auth.signInWithPassword({ email, password });
      if (error) throw error;
      return { success: true };
    } catch (error) {
      return { success: false, message: error.error_description || error.message };
    }
  };

  const signOut = async () => {
    const { error } = await supabase.auth.signOut();
    if (!error) {
      if (user) {
        localStorage.removeItem(`user_role_${user.id}`);
      }
    } else {
      console.error('Error signing out:', error.message);
    }
  };

  // Context value to be provided to consuming components
  const value = {
    user,
    role,
    token,
    loading,
    signInWithGoogle,
    signInWithMicrosoft,
    signUpWithEmail,
    signInWithEmail,
    signOut,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

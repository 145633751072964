// src/components/PriceHistoryChart.js
import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
} from 'recharts';
import { format, parse } from 'date-fns';
import PropTypes from 'prop-types';

// Formatting function for Y-axis labels
const formatYAxisNoDecimal = (value) => {
  // Format: $44.9k
  if (value >= 1000) {
    return `$${(value / 1000).toFixed(1)}k`;
  }
  return `$${value}`;
};

const PriceHistoryChart = React.memo(({ priceHistory }) => {
  // Prepare data for the chart
  const data = priceHistory.map((entry) => ({
    date: format(parse(entry.date, 'MM-dd-yyyy', new Date()), 'MMM dd'), // e.g., "Oct 17"
    price: entry.price,
  }));

  return (
    <ResponsiveContainer width="100%" height={150}>
      <LineChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis
          domain={['auto', 'auto']}
          tickFormatter={formatYAxisNoDecimal} // Apply formatting here
        />
        <Tooltip
          formatter={(value) => `$${value.toLocaleString()}`} // Tooltip formatter
          labelFormatter={(label) => `Date: ${label}`} // Tooltip label formatter
        />
        <Line
          type="monotone"
          dataKey="price"
          stroke="#8884d8"
          strokeWidth={2}
          dot={{ r: 3 }}
          activeDot={{ r: 5 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
});

PriceHistoryChart.propTypes = {
  priceHistory: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired, // Date in "MM-DD-YYYY" format
      price: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default PriceHistoryChart;

// src/supabaseclient.js
import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;


if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Supabase URL and Anon Key are required. Check your environment variables.');
}

const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  autoRefreshToken: true,      // Automatically refreshes the access token
  persistSession: true,        // Persists the session in localStorage
  detectSessionInUrl: true,    // Detects session in the URL for OAuth flows
});

export { supabase };

// src/LoginScreen.js
import React, { useState } from 'react';
import { useAuth } from './AuthContext'; // Import useAuth from AuthContext
import logo from './images/mm-logo.png'; // Ensure this path is correct
import './App.css'; // Assuming styles are in App.css

// GoogleLogo and MicrosoftLogo components remain unchanged
const GoogleLogo = () => (
  <svg
    className="google-logo"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
  >
    {/* SVG Paths */}
    <path
      fill="#EA4335"
      d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
    ></path>
    <path
      fill="#4285F4"
      d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
    ></path>
    <path
      fill="#FBBC05"
      d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
    ></path>
    <path
      fill="#34A853"
      d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
    ></path>
    <path fill="none" d="M0 0h48v48H0z"></path>
  </svg>
);

const MicrosoftLogo = () => (
  <svg
    className="microsoft-logo"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
  >
    {/* SVG Paths */}
    <path fill="#F35325" d="M1 1h22v22H1z"></path>
    <path fill="#81BC06" d="M25 1h22v22H25z"></path>
    <path fill="#05A6F0" d="M1 25h22v22H1z"></path>
    <path fill="#FFBA08" d="M25 25h22v22H25z"></path>
  </svg>
);

const LoginScreen = () => {
  const {
    signInWithGoogle,
    signInWithMicrosoft, // Destructure signInWithMicrosoft from context
    signUpWithEmail,
    signInWithEmail,
  } = useAuth(); // Use the useAuth hook to access context methods

  // State to toggle between Login and Signup
  const [isSignup, setIsSignup] = useState(false);

  // New State: Show Email Signup Form
  const [showEmailSignup, setShowEmailSignup] = useState(false);

  // States for Signup Form
  const [signupEmail, setSignupEmail] = useState('');
  const [signupPassword, setSignupPassword] = useState('');

  // States for Login Form
  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');

  // Common States
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  // New State: Signup Success
  const [signupSuccess, setSignupSuccess] = useState(false);

  // Handle Signup with Email
  const handleEmailSignup = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');
    const result = await signUpWithEmail(signupEmail, signupPassword);
    if (result.success) {
      setMessage('Signup successful! Please check your email for confirmation.');
      setSignupSuccess(true); // Set signupSuccess to true
      // Optionally reset the form
      setSignupEmail('');
      setSignupPassword('');
      setShowEmailSignup(false); // Hide the form after successful signup
    } else {
      setMessage(result.message);
    }
    setLoading(false);
  };

  // Handle Login with Email
  const handleEmailLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');
    const result = await signInWithEmail(loginEmail, loginPassword);
    if (result.success) {
      setMessage('Logged in successfully!');
      // Redirect or perform other actions here
    } else {
      setMessage(result.message);
    }
    setLoading(false);
  };

  // Determine Button Texts Based on Mode
  const googleButtonText = isSignup ? 'Sign up with Google' : 'Sign in with Google';
  const microsoftButtonText = isSignup
    ? 'Sign up with Microsoft'
    : 'Sign in with Microsoft';

  // Handle Switching Tabs and Resetting States
  const handleSwitchTab = (signupMode) => {
    setIsSignup(signupMode);
    setMessage('');
    setShowEmailSignup(false); // Reset email signup form when switching tabs
    setSignupSuccess(false); // Reset signup success when switching tabs
  };

  // Handle Reset after Signup Success
  const handleReset = () => {
    setSignupSuccess(false);
    setMessage('');
    setIsSignup(false);
    setShowEmailSignup(false);
    setLoading(false);
  };

  return (
    <div className="login-screen">
      <div className="login-container">
        <img src={logo} alt="Logo" className="login-logo" />
        <h2>{isSignup ? 'Create Account' : 'Login to Your Account'}</h2>

        {/* Display message */}
        {message && <p className="message">{message}</p>}

        {/* If signup is successful, show only the Login button */}
        {signupSuccess ? (
          <div className="signup-success-container">
            <button className="auth-button login-button" onClick={handleReset}>
              Login
            </button>
          </div>
        ) : (
          // Existing Authentication Forms
          !isSignup ? (
            // **Login Tab**
            <div className="auth-form-container">
              {/* Email Login Form */}
              <form onSubmit={handleEmailLogin} className="auth-form">
                <input
                  type="email"
                  placeholder="Email"
                  value={loginEmail}
                  onChange={(e) => setLoginEmail(e.target.value)}
                  required
                />
                <input
                  type="password"
                  placeholder="Password"
                  value={loginPassword}
                  onChange={(e) => setLoginPassword(e.target.value)}
                  required
                />
                <button type="submit" className="auth-button" disabled={loading}>
                  {loading ? 'Logging in...' : 'Login with Email'}
                </button>
              </form>

              {/* Alternative Login Options */}
              <div className="alternative-login">
                <p>Or log in with:</p>

                {/* Google Login */}
                <button
                  className="auth-button google-button"
                  onClick={signInWithGoogle}
                  disabled={loading}
                >
                  <GoogleLogo />
                  <span className="button-text">{googleButtonText}</span>
                </button>

                {/* Microsoft Login */}
                <button
                  className="auth-button microsoft-button"
                  onClick={signInWithMicrosoft} // Use context method
                  disabled={loading}
                >
                  <MicrosoftLogo />
                  <span className="button-text">{microsoftButtonText}</span>
                </button>
              </div>

              {/* Switch to Signup */}
              <div className="switch-auth">
                <p>
                  Don't have an account?{' '}
                  <button
                    className="switch-button"
                    onClick={() => handleSwitchTab(true)}
                  >
                    Create Account
                  </button>
                </p>
              </div>
            </div>
          ) : (
            // **Sign Up Tab**
            <div className="auth-form-container">
              {/* Conditionally Render Email Signup Button or Form */}
              {!showEmailSignup ? (
                // **Initial Sign Up View**
                <>
                  {/* Sign up with Email Button at the Top */}
                  <button
                    className="auth-button email-button"
                    onClick={() => setShowEmailSignup(true)}
                    disabled={loading}
                  >
                    <span className="button-text">Sign up with Email</span>
                  </button>

                  {/* Separator */}
                  <div className="separator separator-signup">
                    <span>Or sign up with</span>
                  </div>

                  {/* Alternative Signup Options */}
                  <div className="alternative-login">
                    {/* Google Signup */}
                    <button
                      className="auth-button google-button"
                      onClick={signInWithGoogle}
                      disabled={loading}
                    >
                      <GoogleLogo />
                      <span className="button-text">{googleButtonText}</span>
                    </button>

                    {/* Microsoft Signup */}
                    <button
                      className="auth-button microsoft-button"
                      onClick={signInWithMicrosoft} // Use context method
                      disabled={loading}
                    >
                      <MicrosoftLogo />
                      <span className="button-text">{microsoftButtonText}</span>
                    </button>
                  </div>
                </>
              ) : (
                // **Email Signup Form with Alternative Options Below**
                <>
                  {/* Email Signup Form */}
                  <form onSubmit={handleEmailSignup} className="auth-form">
                    <input
                      type="email"
                      placeholder="Email"
                      value={signupEmail}
                      onChange={(e) => setSignupEmail(e.target.value)}
                      required
                    />
                    <input
                      type="password"
                      placeholder="Password"
                      value={signupPassword}
                      onChange={(e) => setSignupPassword(e.target.value)}
                      required
                    />
                    <button type="submit" className="auth-button" disabled={loading}>
                      {loading ? 'Signing up...' : 'Create Account'}
                    </button>
                  </form>

                  {/* Separator */}
                  <div className="separator separator-signup">
                    <span>Or sign up with</span>
                  </div>

                  {/* Alternative Signup Options */}
                  <div className="alternative-login">
                    {/* Google Signup */}
                    <button
                      className="auth-button google-button"
                      onClick={signInWithGoogle}
                      disabled={loading}
                    >
                      <GoogleLogo />
                      <span className="button-text">{googleButtonText}</span>
                    </button>

                    {/* Microsoft Signup */}
                    <button
                      className="auth-button microsoft-button"
                      onClick={signInWithMicrosoft} // Use context method
                      disabled={loading}
                    >
                      <MicrosoftLogo />
                      <span className="button-text">{microsoftButtonText}</span>
                    </button>
                  </div>
                </>
              )}

              {/* Switch to Login */}
              <div className="switch-auth">
                <p>
                  Already have an account?{' '}
                  <button
                    className="switch-button"
                    onClick={() => handleSwitchTab(false)}
                  >
                    Login
                  </button>
                </p>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default LoginScreen;

// src/AdminPanel.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from './AuthContext'; // Import AuthContext

function AdminPanel() {
  const { user, token, role, loading } = useAuth(); // Destructure user, token, role, and loading from AuthContext
  const [config, setConfig] = useState(null);
  const [editing, setEditing] = useState(false);
  const [newConfig, setNewConfig] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (!loading) { // Ensure that role is fetched before attempting to fetch config
      fetchConfig();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, role]); // Depend on loading and role

  const fetchConfig = async () => {
    if (!user || !token) {
      setMessage('User not authenticated.');
      return;
    }

    try {
      const response = await axios.get('/.netlify/functions/getConfig', {
        headers: {
          Authorization: `Bearer ${token}`, // Use Supabase token
        },
      });
      setConfig(response.data);
      setNewConfig(JSON.stringify(response.data, null, 2));
    } catch (error) {
      console.error('Error fetching config:', error);
      if (error.response) {
        setMessage(`Error ${error.response.status}: ${error.response.data.message}`);
      } else {
        setMessage('Error fetching config.');
      }
    }
  };

  const handleSave = async () => {
    if (!user || !token) {
      setMessage('User not authenticated.');
      return;
    }

    try {
      const parsedConfig = JSON.parse(newConfig);
      await axios.post(
        '/.netlify/functions/updateConfig',
        { config: parsedConfig },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Use Supabase token
          },
        }
      );
      setConfig(parsedConfig);
      setEditing(false);
      setMessage('Config updated successfully.');
    } catch (error) {
      console.error('Error updating config:', error);
      if (error.response) {
        setMessage(`Error ${error.response.status}: ${error.response.data.message}`);
      } else if (error.name === 'SyntaxError') {
        setMessage('Failed to update config. Ensure JSON is valid.');
      } else {
        setMessage('Failed to update config.');
      }
    }
  };

  const handleEditToggle = () => {
    setEditing(!editing);
    setMessage('');
  };

  const handleChange = (e) => {
    setNewConfig(e.target.value);
  };

  // Debugging logs
  useEffect(() => {
    console.log('AdminPanel - User:', user);
    console.log('AdminPanel - Token:', token);
    console.log('AdminPanel - Role:', role);
    console.log('AdminPanel - Loading:', loading);
  }, [user, token, role, loading]);

  // Handle loading state
  if (loading) {
    return <div>Loading...</div>;
  }

  // Restrict access to admin users only
  if (role?.toLowerCase() !== 'admin') {
    return <div>You do not have permission to access this page.</div>;
  }

  return (
    <div>
      <h1>Admin Panel</h1>
      {message && <p>{message}</p>}
      {config ? (
        <div>
          {!editing ? (
            <div>
              <pre>{JSON.stringify(config, null, 2)}</pre>
              <button onClick={handleEditToggle}>Edit Config</button>
            </div>
          ) : (
            <div>
              <textarea
                value={newConfig}
                onChange={handleChange}
                rows={20}
                cols={80}
                style={{ fontFamily: 'monospace', fontSize: '14px' }}
              />
              <br />
              <button onClick={handleSave}>Save</button>
              <button onClick={handleEditToggle}>Cancel</button>
            </div>
          )}
        </div>
      ) : (
        <p>Loading config...</p>
      )}
    </div>
  );
}

export default AdminPanel;

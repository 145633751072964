// src/Dashboard.js

import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Legend, Tooltip as RechartsTooltip, ResponsiveContainer,
  BarChart, Bar, PieChart, Pie, Cell, ScatterChart, Scatter, ZAxis, LabelList
} from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import "react-toggle/style.css";
import { Switch, Tooltip, Table, Card, message, Radio, Spin, Alert } from 'antd';
import 'antd/dist/reset.css';
import './Dashboard.css';
import bmwMLogo from './images/bmw-m-logo.png';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AA336A', '#AA66CC', '#33CC99', '#FF6666'];

const Dashboard = ({ selectedDealership }) => {
  // State variables for filters
  const [viewMode, setViewMode] = useState('over_time'); // 'live' or 'over_time'
  const [dataFilter, setDataFilter] = useState('all'); // 'all' or 'm_cars'
  const [inventoryType, setInventoryType] = useState('combined'); // 'combined', 'new', 'used'

  // State variables for data fetching
  const [lineChartData, setLineChartData] = useState([]);
  const [barChartData, setBarChartData] = useState([]);
  const [barChartYearBuckets, setBarChartYearBuckets] = useState([]);
  const [pieChartData, setPieChartData] = useState([]);
  const [scatterChartData, setScatterChartData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [mileageDistributionData, setMileageDistributionData] = useState([]);
  const [timeInInventoryData, setTimeInInventoryData] = useState([]);
  const [conditionDistributionData, setConditionDistributionData] = useState([]);
  const [averagePricePerModelData, setAveragePricePerModelData] = useState([]);
  const [averagePricePerYearData, setAveragePricePerYearData] = useState([]);
  const [vehicleAgeDistributionData, setVehicleAgeDistributionData] = useState([]);
  const [topMakesModelsData, setTopMakesModelsData] = useState([]);
  const [averageTimeInInventoryPerModelData, setAverageTimeInInventoryPerModelData] = useState([]);
  const [inventoryValueData, setInventoryValueData] = useState(null);
  const [throughputData, setThroughputData] = useState([]);
  const [priceChangesData, setPriceChangesData] = useState([]);
  const [dataCompletenessData, setDataCompletenessData] = useState([]);
  const [colorDistributionData, setColorDistributionData] = useState([]);
  const [priceMileageRatioData, setPriceMileageRatioData] = useState([]);
  const [correlationData, setCorrelationData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Effect to fetch data whenever filters change
  useEffect(() => {
    if (!selectedDealership) return;

    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        // Construct query parameters
        const params = {
          dealership: selectedDealership,
          inventoryType,
          dataFilter,
          mode: viewMode,
        };

        // Make API request to Netlify function
        const response = await axios.get('/.netlify/functions/fetchStatsData', { params });

        // Validate response data structure
        if (!response.data || typeof response.data !== 'object') {
          throw new Error('Invalid data format received from the server.');
        }

        // Process data based on viewMode
        if (viewMode === 'over_time') {
          // Over Time Data Processing
          const processedLineChartData = processLineChartDataOverTime(response.data);
          setLineChartData(processedLineChartData);

          const { chartData: processedBarChartData, allYearBuckets } = processBarChartDataOverTime(response.data);
          setBarChartData(processedBarChartData);
          setBarChartYearBuckets(allYearBuckets);

          const processedPieChartData = processPieChartDataOverTime(response.data);
          setPieChartData(processedPieChartData);

          const processedScatterChartData = processScatterChartDataOverTime(response.data);
          setScatterChartData(processedScatterChartData);

          const processedTableData = processTableDataOverTime(response.data);
          setTableData(processedTableData);

          // New Data Processing
          setMileageDistributionData(processMileageDistributionOverTime(response.data));
          setTimeInInventoryData(processTimeInInventoryDataOverTime(response.data));
          setConditionDistributionData(processConditionDistributionOverTime(response.data));
          setAveragePricePerModelData(processAveragePricePerModelOverTime(response.data));
          setAveragePricePerYearData(processAveragePricePerYearOverTime(response.data));
          setVehicleAgeDistributionData(processVehicleAgeDistributionOverTime(response.data));
          setTopMakesModelsData(processTopMakesModelsOverTime(response.data));
          setAverageTimeInInventoryPerModelData(processAverageTimeInInventoryPerModelOverTime(response.data));
          setInventoryValueData(processInventoryValueDataOverTime(response.data));
          setThroughputData(processThroughputDataOverTime(response.data));
          setPriceChangesData(processPriceChangesDataOverTime(response.data));
          setDataCompletenessData(processDataCompletenessOverTime(response.data));
          setColorDistributionData(processColorDistributionOverTime(response.data));
          setPriceMileageRatioData(processPriceMileageRatioOverTime(response.data));
          setCorrelationData(processCorrelationDataOverTime(response.data));

        } else if (viewMode === 'live') {
          // Live Data Processing
          const processedLineChartData = processLineChartDataLive(response.data);
          setLineChartData(processedLineChartData);

          const processedBarChartData = processBarChartDataLive(response.data);
          setBarChartData(processedBarChartData);

          const processedPieChartData = processPieChartDataLive(response.data);
          setPieChartData(processedPieChartData);

          const processedScatterChartData = processScatterChartDataLive(response.data);
          setScatterChartData(processedScatterChartData);

          const processedTableData = processTableDataLive(response.data);
          setTableData(processedTableData);

          // New Data Processing
          setMileageDistributionData(processMileageDistributionLive(response.data));
          setTimeInInventoryData(processTimeInInventoryDataLive(response.data));
          setConditionDistributionData(processConditionDistributionLive(response.data));
          setAveragePricePerModelData(processAveragePricePerModelLive(response.data));
          setAveragePricePerYearData(processAveragePricePerYearLive(response.data));
          setVehicleAgeDistributionData(processVehicleAgeDistributionLive(response.data));
          setTopMakesModelsData(processTopMakesModelsLive(response.data));
          setAverageTimeInInventoryPerModelData(processAverageTimeInInventoryPerModelLive(response.data));
          setInventoryValueData(processInventoryValueDataLive(response.data));
          setThroughputData(processThroughputDataLive(response.data));
          setPriceChangesData(processPriceChangesDataLive(response.data));
          setDataCompletenessData(processDataCompletenessLive(response.data));
          setColorDistributionData(processColorDistributionLive(response.data));
          setPriceMileageRatioData(processPriceMileageRatioLive(response.data));
          setCorrelationData(processCorrelationDataLive(response.data));
        } else {
          throw new Error('Unknown view mode.');
        }
      } catch (err) {
        console.error('Error fetching data:', err);
        if (err.response && err.response.data && err.response.data.error) {
          setError(err.response.data.error);
          message.error(err.response.data.error);
        } else if (err.message) {
          setError(err.message);
          message.error(err.message);
        } else {
          setError('An unexpected error occurred while fetching data.');
          message.error('An unexpected error occurred while fetching data.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedDealership, viewMode, dataFilter, inventoryType]);

  // Handler functions for filters
  const handleViewModeToggle = (checked) => {
    setViewMode(checked ? 'live' : 'over_time');
  };

  const handleDataFilterToggle = (checked) => {
    setDataFilter(checked ? 'm_cars' : 'all');
  };

  const handleInventoryTypeChange = (e) => {
    setInventoryType(e.target.value);
  };

  // Define columns for the table
  const columns = useMemo(() => [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      sorter: (a, b) => {
        if (a.date === 'Live') return -1;
        if (b.date === 'Live') return 1;
        return new Date(a.date) - new Date(b.date);
      },
    },
    {
      title: 'Price Mean',
      dataIndex: 'price_mean',
      key: 'price_mean',
      sorter: (a, b) => (a.price_mean || 0) - (b.price_mean || 0),
      render: (value) => value !== null && value !== undefined ? `$${value.toLocaleString()}` : 'N/A',
    },
    {
      title: 'Price Median',
      dataIndex: 'price_median',
      key: 'price_median',
      sorter: (a, b) => (a.price_median || 0) - (b.price_median || 0),
      render: (value) => value !== null && value !== undefined ? `$${value.toLocaleString()}` : 'N/A',
    },
    {
      title: 'Year Mean',
      dataIndex: 'year_mean',
      key: 'year_mean',
      sorter: (a, b) => (a.year_mean || 0) - (b.year_mean || 0),
      render: (value) => value !== null && value !== undefined ? value : 'N/A',
    },
    {
      title: 'Year Median',
      dataIndex: 'year_median',
      key: 'year_median',
      sorter: (a, b) => (a.year_median || 0) - (b.year_median || 0),
      render: (value) => value !== null && value !== undefined ? value : 'N/A',
    },
    {
      title: 'Turnover Rate',
      dataIndex: 'turnover_rate',
      key: 'turnover_rate',
      sorter: (a, b) => (a.turnover_rate || 0) - (b.turnover_rate || 0),
      render: (value) => value !== null && value !== undefined ? `${(value * 100).toFixed(2)}%` : 'N/A',
    },
    {
      title: 'Total Inventory',
      dataIndex: 'total_inventory',
      key: 'total_inventory',
      sorter: (a, b) => (a.total_inventory || 0) - (b.total_inventory || 0),
      render: (value) => value !== null && value !== undefined ? value : 'N/A',
    },
  ], []);

  // Function to render the info icon with tooltip
  const renderInfoIcon = (infoText) => (
    <Tooltip title={infoText}>
      <FontAwesomeIcon
        icon={faInfoCircle}
        style={{ marginLeft: '8px', color: '#1890ff', cursor: 'pointer' }}
        aria-label="Information"
        role="img"
      />
    </Tooltip>
  );

  // Render functions for charts and cards

  // Render Line Chart
  const renderLineChart = () => {
    if (!lineChartData || lineChartData.length === 0) {
      return <Alert message="No Line Chart data available." type="info" showIcon />;
    }

    return (
      <Card
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            Price Trends Over Time
            {renderInfoIcon('This chart displays the mean and median prices of the inventory over selected dates.')}
          </div>
        }
      >
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={lineChartData}>
            <CartesianGrid stroke="#ccc" />
            <XAxis dataKey={viewMode === 'live' ? 'label' : 'date'} />
            <YAxis />
            <RechartsTooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="price_mean"
              name="Price Mean"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
              connectNulls
            />
            <Line
              type="monotone"
              dataKey="price_median"
              name="Price Median"
              stroke="#82ca9d"
              connectNulls
            />
          </LineChart>
        </ResponsiveContainer>
      </Card>
    );
  };

  // Render Bar Chart
  const renderBarChart = () => {
    if (!barChartData || barChartData.length === 0) {
      return <Alert message="No Bar Chart data available." type="info" showIcon />;
    }

    if (viewMode === 'live') {
      // In Live Mode, render a simple bar chart
      return (
        <Card
          title={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              Live Price Bucket Counts
              {renderInfoIcon('This bar chart shows the distribution of vehicle counts across different price buckets in real-time.')}
            </div>
          }
        >
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={barChartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="bucket" />
              <YAxis />
              <RechartsTooltip />
              <Legend />
              <Bar dataKey="count" name="Count" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </Card>
      );
    }

    // Over Time Mode: Render the Bar Chart as a grouped chart
    return (
      <Card
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            Year Bucket Counts Over Time
            {renderInfoIcon('This bar chart compares the number of vehicles in different year buckets across selected dates.')}
          </div>
        }
      >
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={barChartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <RechartsTooltip />
            <Legend />
            {barChartYearBuckets.map((bucket, index) => (
              <Bar
                key={bucket}
                dataKey={bucket}
                name={bucket}
                stackId="a"
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </Card>
    );
  };


  // Render Pie Chart
  const renderPieChart = () => {
    if (!pieChartData || pieChartData.length === 0) {
      return <Alert message="No Pie Chart data available." type="info" showIcon />;
    }

    return (
      <Card
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            Price Bucket Distribution
            {renderInfoIcon('This pie chart shows the distribution of vehicle prices within different price buckets.')}
          </div>
        }
      >
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={pieChartData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={100}
              fill="#8884d8"
              label
            >
              {pieChartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <RechartsTooltip />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </Card>
    );
  };

  // Render Scatter Chart
  const renderScatterChart = () => {
    if (!scatterChartData || scatterChartData.length === 0) {
      return <Alert message="No Scatter Chart data available." type="info" showIcon />;
    }

    return (
      <Card
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            Price Mean vs. Year Mean with Turnover Rate
            {renderInfoIcon('This scatter chart illustrates the relationship between the average price and average year of the inventory. The size of each point represents the turnover rate.')}
          </div>
        }
      >
        <ResponsiveContainer width="100%" height={400}>
          <ScatterChart>
            <CartesianGrid />
            <XAxis type="number" dataKey="year_mean" name="Year Mean" />
            <YAxis type="number" dataKey="price_mean" name="Price Mean" />
            <ZAxis type="number" dataKey="turnover_rate" range={[60, 400]} name="Turnover Rate" />
            <RechartsTooltip cursor={{ strokeDasharray: '3 3' }} />
            <Legend />
            <Scatter name="Turnover Rate" data={scatterChartData} fill="#8884d8" />
          </ScatterChart>
        </ResponsiveContainer>
      </Card>
    );
  };

  // Render Data Table
  const renderTable = () => {
    if (!tableData || tableData.length === 0) {
      return <Alert message="No table data available." type="info" showIcon />;
    }

    return (
      <Card title="Detailed Inventory Statistics">
        <Table
          columns={columns}
          dataSource={tableData}
          pagination={{ pageSize: 10 }}
          bordered
          scroll={{ x: 'max-content' }}
        />
      </Card>
    );
  };

  // Render Mileage Distribution Chart
  const renderMileageDistributionChart = () => {
    if (!mileageDistributionData || mileageDistributionData.length === 0) {
      return <Alert message="No Mileage Distribution data available." type="info" showIcon />;
    }

    return (
      <Card
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            Mileage Distribution
            {renderInfoIcon('This chart shows the distribution of vehicles across different mileage buckets.')}
          </div>
        }
      >
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={mileageDistributionData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="bucket" />
            <YAxis />
            <RechartsTooltip />
            <Legend />
            <Bar dataKey="count" name="Count" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      </Card>
    );
  };

  // Render Time in Inventory Chart
  const renderTimeInInventoryChart = () => {
    if (!timeInInventoryData || timeInInventoryData.length === 0) {
      return <Alert message="No Time in Inventory data available." type="info" showIcon />;
    }

    return (
      <Card
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            Time in Inventory Distribution
            {renderInfoIcon('This chart shows how long vehicles have been in inventory across different time buckets.')}
          </div>
        }
      >
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={timeInInventoryData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="bucket" />
            <YAxis />
            <RechartsTooltip />
            <Legend />
            <Bar dataKey="count" name="Count" fill="#82ca9d" />
          </BarChart>
        </ResponsiveContainer>
      </Card>
    );
  };

  // Render Condition Distribution Chart
  const renderConditionDistributionChart = () => {
    if (!conditionDistributionData || conditionDistributionData.length === 0) {
      return <Alert message="No Condition Distribution data available." type="info" showIcon />;
    }

    return (
      <Card
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            Condition Distribution
            {renderInfoIcon('This pie chart shows the proportion of new vs used vehicles in inventory.')}
          </div>
        }
      >
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={conditionDistributionData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={100}
              fill="#8884d8"
              label
            >
              {conditionDistributionData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <RechartsTooltip />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </Card>
    );
  };

  // Render Average Price per Model Chart
  const renderAveragePricePerModelChart = () => {
    if (!averagePricePerModelData || averagePricePerModelData.length === 0) {
      return <Alert message="No Average Price per Model data available." type="info" showIcon />;
    }

    return (
      <Card
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            Average Price per Model
            {renderInfoIcon('This bar chart shows the average price for each model in the inventory.')}
          </div>
        }
      >
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={averagePricePerModelData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="make_model" angle={-45} textAnchor="end" interval={0} height={100} />
            <YAxis />
            <RechartsTooltip />
            <Legend />
            <Bar dataKey="average_price" name="Average Price" fill="#ffc658" />
          </BarChart>
        </ResponsiveContainer>
      </Card>
    );
  };

  // Render Average Price per Year Chart
  const renderAveragePricePerYearChart = () => {
    if (!averagePricePerYearData || averagePricePerYearData.length === 0) {
      return <Alert message="No Average Price per Year data available." type="info" showIcon />;
    }

    return (
      <Card
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            Average Price per Year
            {renderInfoIcon('This line chart shows the average price of vehicles for each manufacturing year.')}
          </div>
        }
      >
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={averagePricePerYearData}>
            <CartesianGrid stroke="#ccc" />
            <XAxis dataKey="year" />
            <YAxis />
            <RechartsTooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="average_price"
              name="Average Price"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
              connectNulls
            />
          </LineChart>
        </ResponsiveContainer>
      </Card>
    );
  };

  // Render Vehicle Age Distribution Chart
  const renderVehicleAgeDistributionChart = () => {
    if (!vehicleAgeDistributionData || vehicleAgeDistributionData.length === 0) {
      return <Alert message="No Vehicle Age Distribution data available." type="info" showIcon />;
    }

    return (
      <Card
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            Vehicle Age Distribution
            {renderInfoIcon('This bar chart shows the distribution of vehicle ages in the inventory.')}
          </div>
        }
      >
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={vehicleAgeDistributionData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="age" />
            <YAxis />
            <RechartsTooltip />
            <Legend />
            <Bar dataKey="count" name="Count" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      </Card>
    );
  };

  // Render Top Makes and Models Chart
  const renderTopMakesModelsChart = () => {
    if (!topMakesModelsData || topMakesModelsData.length === 0) {
      return <Alert message="No Top Makes and Models data available." type="info" showIcon />;
    }

    return (
      <Card
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            Top Makes and Models
            {renderInfoIcon('This bar chart shows the top makes and models by count in the inventory.')}
          </div>
        }
      >
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={topMakesModelsData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="make_model" angle={-45} textAnchor="end" interval={0} height={100} />
            <YAxis />
            <RechartsTooltip />
            <Legend />
            <Bar dataKey="count" name="Count" fill="#82ca9d" />
          </BarChart>
        </ResponsiveContainer>
      </Card>
    );
  };

  // Render Average Time in Inventory per Model Chart
  const renderAverageTimeInInventoryPerModelChart = () => {
    if (!averageTimeInInventoryPerModelData || averageTimeInInventoryPerModelData.length === 0) {
      return <Alert message="No Average Time in Inventory per Model data available." type="info" showIcon />;
    }

    return (
      <Card
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            Average Time in Inventory per Model
            {renderInfoIcon('This bar chart shows the average time each model spends in inventory.')}
          </div>
        }
      >
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={averageTimeInInventoryPerModelData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="make_model" angle={-45} textAnchor="end" interval={0} height={100} />
            <YAxis />
            <RechartsTooltip />
            <Legend />
            <Bar dataKey="average_time" name="Average Time (days)" fill="#ffc658" />
          </BarChart>
        </ResponsiveContainer>
      </Card>
    );
  };

  // Render Inventory Value Card
  // Render Inventory Value Card
  const renderInventoryValueCard = () => {
    if (!inventoryValueData || (Array.isArray(inventoryValueData) && inventoryValueData.length === 0)) {
      return <Alert message="No Inventory Value data available." type="info" showIcon />;
    }
  
    if (viewMode === 'live') {
      // Live mode rendering as before
      const data = [
        { name: 'Total Inventory Value', value: inventoryValueData.total_inventory_value },
        { name: 'Average Vehicle Value', value: inventoryValueData.average_vehicle_value },
      ];
  
      return (
        <Card
          title={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              Inventory Value
              {renderInfoIcon('This chart displays the total and average inventory value.')}
            </div>
          }
        >
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis tickFormatter={(value) => `$${(value / 1e6).toFixed(1)}M`} />
              <RechartsTooltip formatter={(value) => `$${value.toLocaleString()}`} />
              <Legend />
              <Bar dataKey="value" name="Value" fill="#8884d8">
                <LabelList dataKey="value" position="top" formatter={(value) => `$${(value / 1e6).toFixed(1)}M`} />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Card>
      );
    } else {
      // Over time mode rendering with updated date formatting
      return (
        <Card
          title={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              Inventory Value Over Time
              {renderInfoIcon('This chart displays the total and average inventory value over time.')}
            </div>
          }
        >
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={inventoryValueData}>
              <CartesianGrid stroke="#ccc" />
              <XAxis
                dataKey="parsedDate"
                type="number"
                domain={['dataMin', 'dataMax']}
                scale="time"
                tickFormatter={(tick) => {
                  const date = new Date(tick);
                  const month = String(date.getMonth() + 1).padStart(2, '0');
                  const day = String(date.getDate()).padStart(2, '0');
                  const year = date.getFullYear();
                  return `${month}-${day}-${year}`;
                }}
              />
              <YAxis
                yAxisId="left"
                orientation="left"
                tickFormatter={(value) => `$${(value / 1e6).toFixed(1)}M`}
              />
              <YAxis
                yAxisId="right"
                orientation="right"
                tickFormatter={(value) => `$${(value / 1e3).toFixed(1)}K`}
              />
              <RechartsTooltip
                formatter={(value) => `$${value.toLocaleString()}`}
                labelFormatter={(label) => {
                  const date = new Date(label);
                  const month = String(date.getMonth() + 1).padStart(2, '0');
                  const day = String(date.getDate()).padStart(2, '0');
                  const year = date.getFullYear();
                  return `Date: ${month}-${day}-${year}`;
                }}
              />
              <Legend />
              <Line
                yAxisId="left"
                type="monotone"
                dataKey="total_inventory_value"
                name="Total Inventory Value"
                stroke="#8884d8"
                connectNulls
              />
              <Line
                yAxisId="right"
                type="monotone"
                dataKey="average_vehicle_value"
                name="Average Vehicle Value"
                stroke="#82ca9d"
                connectNulls
              />
            </LineChart>
          </ResponsiveContainer>
        </Card>
      );
    }
  };
  
  


  

  // Render Throughput Chart
  const renderThroughputChart = () => {
    if (!throughputData || throughputData.length === 0) {
      return <Alert message="No Throughput data available." type="info" showIcon />;
    }

    return (
      <Card
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            Inventory Throughput Over Time
            {renderInfoIcon('This chart shows the number of cars added to and removed from inventory over time.')}
          </div>
        }
      >
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={throughputData}>
            <CartesianGrid stroke="#ccc" />
            <XAxis dataKey={viewMode === 'live' ? 'label' : 'date'} />
            <YAxis />
            <RechartsTooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="added"
              name="Cars Added"
              stroke="#82ca9d"
              activeDot={{ r: 8 }}
            />
            <Line
              type="monotone"
              dataKey="removed"
              name="Cars Removed"
              stroke="#ff7300"
            />
          </LineChart>
        </ResponsiveContainer>
      </Card>
    );
  };

  // Render Price Changes Histogram
  const renderPriceChangesHistogram = () => {
    if (!priceChangesData || priceChangesData.length === 0) {
      return <Alert message="No Price Changes data available." type="info" showIcon />;
    }

    return (
      <Card
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            Price Changes Distribution
            {renderInfoIcon('This histogram shows the distribution of vehicle price changes.')}
          </div>
        }
      >
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={priceChangesData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="price_change_bucket" />
            <YAxis />
            <RechartsTooltip />
            <Legend />
            <Bar dataKey="count" name="Count" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      </Card>
    );
  };

  // Render Data Completeness Chart
  const renderDataCompletenessChart = () => {
    if (!dataCompletenessData || dataCompletenessData.length === 0) {
      return <Alert message="No Data Completeness data available." type="info" showIcon />;
    }

    return (
      <Card
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            Data Completeness
            {renderInfoIcon('This bar chart shows the completeness percentage of each data field.')}
          </div>
        }
      >
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={dataCompletenessData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="field" angle={-45} textAnchor="end" interval={0} height={100} />
            <YAxis domain={[0, 100]} unit="%" />
            <RechartsTooltip />
            <Legend />
            <Bar dataKey="completeness" name="Completeness (%)" fill="#82ca9d" />
          </BarChart>
        </ResponsiveContainer>
      </Card>
    );
  };

  // Render Color Distribution Chart
  const renderColorDistributionChart = () => {
    if (!colorDistributionData || colorDistributionData.length === 0) {
      return <Alert message="No Color Distribution data available." type="info" showIcon />;
    }

    return (
      <Card
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            Color Distribution
            {renderInfoIcon('This pie chart shows the distribution of vehicle colors in the inventory.')}
          </div>
        }
      >
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={colorDistributionData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={100}
              fill="#8884d8"
              label
            >
              {colorDistributionData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <RechartsTooltip />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </Card>
    );
  };

  // Render Price vs Mileage Ratio Chart
  const renderPriceMileageRatioChart = () => {
    if (!priceMileageRatioData || priceMileageRatioData.length === 0) {
      return <Alert message="No Price vs Mileage Ratio data available." type="info" showIcon />;
    }

    return (
      <Card
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            Price vs Mileage Ratio Distribution
            {renderInfoIcon('This chart shows the distribution of the price-to-mileage ratio of vehicles.')}
          </div>
        }
      >
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={priceMileageRatioData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="ratio_bucket" />
            <YAxis />
            <RechartsTooltip />
            <Legend />
            <Bar dataKey="count" name="Count" fill="#ffc658" />
          </BarChart>
        </ResponsiveContainer>
      </Card>
    );
  };

  // Render Correlation Chart
  const renderCorrelationChart = () => {
    if (!correlationData || correlationData.length === 0) {
      return <Alert message="No Correlation data available." type="info" showIcon />;
    }

    // Display correlations in a table
    return (
      <Card
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            Correlations
            {renderInfoIcon('This table shows the correlation coefficients between different variables.')}
          </div>
        }
      >
        <Table
          columns={[
            { title: 'Variable Pair', dataIndex: 'pair', key: 'pair' },
            { title: 'Correlation Coefficient', dataIndex: 'correlation', key: 'correlation' },
          ]}
          dataSource={correlationData}
          pagination={false}
        />
      </Card>
    );
  };

  // Adjusted layout to display charts in a grid with two per row
  return (
    <div className="dashboard-container">
      {selectedDealership && (
        <div className="controls-container">
          <div className="control-group">
            {/* View Mode Toggle */}
            <div className="view-mode-toggle">
              <span className="toggle-label">View Mode:</span>
              <Tooltip title={`Switch to ${viewMode === 'over_time' ? 'Live' : 'Over Time'} View`}>
                <Switch
                  checked={viewMode === 'live'}
                  onChange={handleViewModeToggle}
                  checkedChildren="Live"
                  unCheckedChildren="Over Time"
                  className="custom-switch"
                />
              </Tooltip>
            </div>

            {/* BMW M Filter Toggle */}
            <div className="data-filter-toggle">
              <span className="toggle-label">BMW M:</span>
              <Tooltip title={`Switch to ${dataFilter === 'all' ? 'BMW M Cars' : 'All Cars'}`}>
                <Switch
                  checked={dataFilter === 'm_cars'}
                  onChange={handleDataFilterToggle}
                  checkedChildren={<img src={bmwMLogo} alt="M Cars" className="bmw-m-logo-toggle" />}
                  unCheckedChildren="All"
                  className="custom-switch"
                />
              </Tooltip>
            </div>

            {/* Inventory Type Radio Group */}
            <div className="inventory-type-toggle">
              <span className="toggle-label">Inventory Type:</span>
              <Radio.Group
                onChange={handleInventoryTypeChange}
                value={inventoryType}
                optionType="button"
                buttonStyle="solid"
                className="inventory-type-radio-group"
              >
                <Radio.Button value="combined">All</Radio.Button>
                <Radio.Button value="new">New</Radio.Button>
                <Radio.Button value="used">Used</Radio.Button>
              </Radio.Group>
            </div>
          </div>
        </div>
      )}

      {/* Data Visualization */}
      <div className="charts-container">
        {loading ? (
          <div className="spinner-container">
            <Spin tip="Loading data..." size="large" />
          </div>
        ) : error ? (
          <Alert message="Error" description={error} type="error" showIcon />
        ) : (
          <>
            {renderLineChart()}
            {renderBarChart()}
            {renderPieChart()}
            {renderScatterChart()}
            {renderMileageDistributionChart()}
            {renderTimeInInventoryChart()}
            {renderConditionDistributionChart()}
            {renderAveragePricePerModelChart()}
            {renderAveragePricePerYearChart()}
            {renderVehicleAgeDistributionChart()}
            {renderTopMakesModelsChart()}
            {renderAverageTimeInInventoryPerModelChart()}
            {renderInventoryValueCard()}
            {renderThroughputChart()}
            {renderPriceChangesHistogram()}
            {renderDataCompletenessChart()}
            {renderColorDistributionChart()}
            {renderPriceMileageRatioChart()}
            {renderCorrelationChart()}
            {renderTable()}
          </>
        )}
      </div>
    </div>
  );
};

// Data Processing Functions

// Over Time Processing Functions

const processLineChartDataOverTime = (data) => {
  const chartData = [];

  Object.keys(data).forEach(dateKey => {
    const dateData = data[dateKey];
    const inventoryStats = dateData?.inventory_statistics;

    if (inventoryStats) {
      const priceMean = inventoryStats.price_mean ?? null;
      const priceMedian = inventoryStats.price_median ?? null;

      // Only include entries with at least one valid price metric
      if (priceMean !== null || priceMedian !== null) {
        chartData.push({
          date: dateKey,
          price_mean: priceMean,
          price_median: priceMedian,
        });
      }
    }
  });

  // Sort by date
  chartData.sort((a, b) => new Date(a.date) - new Date(b.date));

  return chartData;
};

const processBarChartDataOverTime = (data) => {
  const chartDataMap = {};
  const allYearBuckets = new Set();

  Object.keys(data).forEach(dateKey => {
    const dateData = data[dateKey];
    const yearBucketCounts = dateData?.inventory_statistics?.year_bucket_counts;

    if (yearBucketCounts && typeof yearBucketCounts === 'object') {
      if (!chartDataMap[dateKey]) {
        chartDataMap[dateKey] = { date: dateKey };
      }
      Object.keys(yearBucketCounts).forEach(bucket => {
        const count = yearBucketCounts[bucket] ?? 0;
        chartDataMap[dateKey][bucket] = count;
        allYearBuckets.add(bucket);
      });
    }
  });

  const chartData = Object.values(chartDataMap);

  // Sort by date
  chartData.sort((a, b) => new Date(a.date) - new Date(b.date));

  return { chartData, allYearBuckets: Array.from(allYearBuckets) };
};

const processPieChartDataOverTime = (data) => {
  const dates = Object.keys(data);
  if (dates.length === 0) return [];

  // Sort dates to find the latest
  dates.sort((a, b) => new Date(a) - new Date(b));
  const latestDate = dates[dates.length - 1];
  const latestData = data[latestDate];
  const priceBucketCounts = latestData?.inventory_statistics?.price_bucket_counts;

  if (priceBucketCounts && typeof priceBucketCounts === 'object') {
    const pieData = Object.keys(priceBucketCounts).map(bucket => ({
      name: bucket,
      value: priceBucketCounts[bucket] ?? 0,
    })).filter(item => item.value > 0); // Filter out zero counts

    return pieData;
  }

  return [];
};

const processScatterChartDataOverTime = (data) => {
  const chartData = [];

  Object.keys(data).forEach(dateKey => {
    const dateData = data[dateKey];
    const inventoryStats = dateData?.inventory_statistics;

    if (inventoryStats) {
      const priceMean = inventoryStats.price_mean;
      const yearMean = inventoryStats.year_mean;
      const turnoverRate = inventoryStats.turnover_rate;

      // Handle null or undefined values by setting them to null
      const validPriceMean = priceMean ?? null;
      const validYearMean = yearMean ?? null;
      const validTurnoverRate = turnoverRate ?? null;

      // Only include entries where all required fields are present
      if (validPriceMean !== null && validYearMean !== null && validTurnoverRate !== null) {
        chartData.push({
          date: dateKey,
          price_mean: validPriceMean,
          year_mean: validYearMean,
          turnover_rate: validTurnoverRate, // Using turnover_rate as a size indicator
        });
      }
    }
  });

  return chartData;
};

const processTableDataOverTime = (data) => {
  if (!data || typeof data !== 'object') return [];

  const tableEntries = [];

  Object.keys(data).forEach(dateKey => {
    const dateData = data[dateKey];
    const inventoryStats = dateData?.inventory_statistics;
    const throughput = dateData?.throughput;

    if (inventoryStats) {
      tableEntries.push({
        key: dateKey,
        date: dateKey,
        price_mean: inventoryStats.price_mean ?? null,
        price_median: inventoryStats.price_median ?? null,
        year_mean: inventoryStats.year_mean ?? null,
        year_median: inventoryStats.year_median ?? null,
        turnover_rate: inventoryStats.turnover_rate ?? null,
        total_inventory: throughput?.total_inventory ?? null,
      });
    }
  });

  // Sort by date
  tableEntries.sort((a, b) => new Date(a.date) - new Date(b.date));

  return tableEntries;
};

// Live Processing Functions

const processLineChartDataLive = (data) => {
  const inventoryStats = data.inventory_statistics;
  const priceMean = inventoryStats.price_mean ?? null;
  const priceMedian = inventoryStats.price_median ?? null;

  return [{
    label: 'Live',
    price_mean: priceMean,
    price_median: priceMedian,
  }];
};

const processBarChartDataLive = (data) => {
  const priceBucketCounts = data.inventory_statistics.price_bucket_counts;
  return Object.keys(priceBucketCounts).map(bucket => ({
    bucket,
    count: priceBucketCounts[bucket] ?? 0,
  }));
};

const processPieChartDataLive = (data) => {
  const priceBucketCounts = data.inventory_statistics.price_bucket_counts;
  return Object.keys(priceBucketCounts).map(bucket => ({
    name: bucket,
    value: priceBucketCounts[bucket] ?? 0,
  })).filter(item => item.value > 0); // Filter out zero counts
};

const processScatterChartDataLive = (data) => {
  const inventoryStats = data.inventory_statistics;
  const priceMean = inventoryStats.price_mean ?? null;
  const yearMean = inventoryStats.year_mean ?? null;
  const turnoverRate = inventoryStats.turnover_rate ?? null;

  if (priceMean !== null && yearMean !== null && turnoverRate !== null) {
    return [{
      price_mean: priceMean,
      year_mean: yearMean,
      turnover_rate: turnoverRate,
    }];
  }

  return [];
};

const processTableDataLive = (data) => {
  const inventoryStats = data.inventory_statistics;
  const throughput = data.throughput;

  return [{
    key: 'live',
    date: 'Live',
    price_mean: inventoryStats.price_mean ?? null,
    price_median: inventoryStats.price_median ?? null,
    year_mean: inventoryStats.year_mean ?? null,
    year_median: inventoryStats.year_median ?? null,
    turnover_rate: inventoryStats.turnover_rate ?? null,
    total_inventory: throughput?.total_inventory ?? null,
  }];
};

// Additional Data Processing Functions

// Over Time Processing Functions

const processMileageDistributionOverTime = (data) => {
  const dates = Object.keys(data);
  if (dates.length === 0) return [];

  // Use the latest date's data
  dates.sort((a, b) => new Date(a) - new Date(b));
  const latestDate = dates[dates.length - 1];
  const latestData = data[latestDate];
  const mileageBucketCounts = latestData?.inventory_statistics?.mileage_bucket_counts;

  if (mileageBucketCounts && typeof mileageBucketCounts === 'object') {
    return Object.keys(mileageBucketCounts).map(bucket => ({
      bucket,
      count: mileageBucketCounts[bucket] ?? 0,
    }));
  }

  return [];
};

const processTimeInInventoryDataOverTime = (data) => {
  const dates = Object.keys(data);
  if (dates.length === 0) return [];

  dates.sort((a, b) => new Date(a) - new Date(b));
  const latestDate = dates[dates.length - 1];
  const latestData = data[latestDate];
  const timeInInventoryCounts = latestData?.inventory_statistics?.time_in_inventory_bucket_counts;

  if (timeInInventoryCounts && typeof timeInInventoryCounts === 'object') {
    return Object.keys(timeInInventoryCounts).map(bucket => ({
      bucket,
      count: timeInInventoryCounts[bucket] ?? 0,
    }));
  }

  return [];
};

const processConditionDistributionOverTime = (data) => {
  const dates = Object.keys(data);
  if (dates.length === 0) return [];

  dates.sort((a, b) => new Date(a) - new Date(b));
  const latestDate = dates[dates.length - 1];
  const latestData = data[latestDate];
  const conditionDistribution = latestData?.condition_distribution;

  if (conditionDistribution && typeof conditionDistribution === 'object') {
    return Object.keys(conditionDistribution).map(condition => ({
      name: condition.charAt(0).toUpperCase() + condition.slice(1),
      value: conditionDistribution[condition] ?? 0,
    }));
  }

  return [];
};

const processAveragePricePerModelOverTime = (data) => {
  const dates = Object.keys(data);
  if (dates.length === 0) return [];

  dates.sort((a, b) => new Date(a) - new Date(b));
  const latestDate = dates[dates.length - 1];
  const latestData = data[latestDate];
  const averagePricePerModel = latestData?.make_model_insights?.average_price_per_model;

  if (averagePricePerModel && Array.isArray(averagePricePerModel)) {
    return averagePricePerModel.map(item => ({
      make_model: `${item.Make} ${item.Model}`,
      average_price: item.Price ?? 0,
    }));
  }

  return [];
};

const processAveragePricePerYearOverTime = (data) => {
  const dates = Object.keys(data);
  if (dates.length === 0) return [];

  dates.sort((a, b) => new Date(a) - new Date(b));
  const latestDate = dates[dates.length - 1];
  const latestData = data[latestDate];
  const averagePricePerYear = latestData?.average_price_per_year;

  if (averagePricePerYear && typeof averagePricePerYear === 'object') {
    return Object.keys(averagePricePerYear).map(year => ({
      year,
      average_price: averagePricePerYear[year] ?? 0,
    }));
  }

  return [];
};

const processVehicleAgeDistributionOverTime = (data) => {
  const dates = Object.keys(data);
  if (dates.length === 0) return [];

  dates.sort((a, b) => new Date(a) - new Date(b));
  const latestDate = dates[dates.length - 1];
  const latestData = data[latestDate];
  const ageDistribution = latestData?.vehicle_age_statistics?.age_distribution;

  if (ageDistribution && typeof ageDistribution === 'object') {
    return Object.keys(ageDistribution).map(age => ({
      age,
      count: ageDistribution[age] ?? 0,
    }));
  }

  return [];
};

const processTopMakesModelsOverTime = (data) => {
  const dates = Object.keys(data);
  if (dates.length === 0) return [];

  dates.sort((a, b) => new Date(a) - new Date(b));
  const latestDate = dates[dates.length - 1];
  const latestData = data[latestDate];
  const topMakesModels = latestData?.make_model_insights?.top_makes_models;

  if (topMakesModels && Array.isArray(topMakesModels)) {
    return topMakesModels.map(item => ({
      make_model: `${item.Make} ${item.Model}`,
      count: item.count ?? 0,
    }));
  }

  return [];
};

const processAverageTimeInInventoryPerModelOverTime = (data) => {
  const dates = Object.keys(data);
  if (dates.length === 0) return [];

  dates.sort((a, b) => new Date(a) - new Date(b));
  const latestDate = dates[dates.length - 1];
  const latestData = data[latestDate];
  const avgTimePerModel = latestData?.average_time_in_inventory_per_make_model;

  if (avgTimePerModel && Array.isArray(avgTimePerModel)) {
    return avgTimePerModel.map(item => ({
      make_model: `${item.Make} ${item.Model}`,
      average_time: item.time_in_inventory ?? 0,
    }));
  }

  return [];
};

const parseDateString = (dateStr) => {
  const [month, day, year] = dateStr.split('-').map(Number);
  return new Date(year, month - 1, day);
};

const processInventoryValueDataOverTime = (data) => {
  const dates = Object.keys(data);
  if (dates.length === 0) return [];

  const chartData = dates.map(dateKey => {
    const dateData = data[dateKey];
    const inventoryValue = dateData?.inventory_value;

    const parsedDate = parseDateString(dateKey);
    const timestamp = parsedDate.getTime(); // Convert Date object to timestamp

    if (inventoryValue && typeof inventoryValue === 'object') {
      return {
        date: dateKey,
        parsedDate: timestamp,
        total_inventory_value: inventoryValue.total_inventory_value ?? null,
        average_vehicle_value: inventoryValue.average_vehicle_value ?? null,
      };
    } else {
      return {
        date: dateKey,
        parsedDate: timestamp,
        total_inventory_value: null,
        average_vehicle_value: null,
      };
    }
  });

  // Sort by parsedDate (timestamp)
  chartData.sort((a, b) => a.parsedDate - b.parsedDate);

  return chartData;
};



const processThroughputDataOverTime = (data) => {
  const throughputData = [];

  Object.keys(data).forEach(dateKey => {
    const dateData = data[dateKey];
    const throughput = dateData?.throughput;

    if (throughput) {
      throughputData.push({
        date: dateKey,
        added: throughput.cars_added_to_inventory ?? 0,
        removed: throughput.cars_removed_from_inventory ?? 0,
      });
    }
  });

  // Sort by date
  throughputData.sort((a, b) => new Date(a.date) - new Date(b.date));

  return throughputData;
};

const processPriceChangesDataOverTime = (data) => {
  const dates = Object.keys(data);
  if (dates.length === 0) return [];

  dates.sort((a, b) => new Date(a) - new Date(b));
  const latestDate = dates[dates.length - 1];
  const priceChanges = data[latestDate]?.price_changes?.price_changes;

  if (priceChanges && Array.isArray(priceChanges)) {
    // Create buckets for price changes
    const buckets = {};

    priceChanges.forEach(change => {
      const priceChange = change.price_change ?? 0;
      let bucket = '';

      if (priceChange < -5000) {
        bucket = '< -$5,000';
      } else if (priceChange < -1000) {
        bucket = '-$5,000 to -$1,000';
      } else if (priceChange < 0) {
        bucket = '-$1,000 to $0';
      } else if (priceChange === 0) {
        bucket = '$0';
      } else if (priceChange <= 1000) {
        bucket = '$0 to $1,000';
      } else if (priceChange <= 5000) {
        bucket = '$1,000 to $5,000';
      } else {
        bucket = '> $5,000';
      }

      buckets[bucket] = (buckets[bucket] || 0) + 1;
    });

    return Object.keys(buckets).map(bucket => ({
      price_change_bucket: bucket,
      count: buckets[bucket],
    }));
  }

  return [];
};

const processDataCompletenessOverTime = (data) => {
  const dates = Object.keys(data);
  if (dates.length === 0) return [];

  dates.sort((a, b) => new Date(a) - new Date(b));
  const latestDate = dates[dates.length - 1];
  const dataCompleteness = data[latestDate]?.data_completeness;

  if (dataCompleteness && typeof dataCompleteness === 'object') {
    return Object.keys(dataCompleteness).map(field => ({
      field,
      completeness: dataCompleteness[field] ?? 0,
    }));
  }

  return [];
};

const processColorDistributionOverTime = (data) => {
  const dates = Object.keys(data);
  if (dates.length === 0) return [];

  dates.sort((a, b) => new Date(a) - new Date(b));
  const latestDate = dates[dates.length - 1];
  const colorDistribution = data[latestDate]?.color_distribution;

  if (colorDistribution && typeof colorDistribution === 'object') {
    return Object.keys(colorDistribution).map(color => ({
      name: color,
      value: colorDistribution[color] ?? 0,
    }));
  }

  return [];
};

const processPriceMileageRatioOverTime = (data) => {
  const dates = Object.keys(data);
  if (dates.length === 0) return [];

  dates.sort((a, b) => new Date(a) - new Date(b));
  const latestDate = dates[dates.length - 1];
  const ratioDistribution = data[latestDate]?.price_mileage_ratio?.distribution;

  if (ratioDistribution && typeof ratioDistribution === 'object') {
    return Object.keys(ratioDistribution).map(ratio => ({
      ratio_bucket: ratio,
      count: ratioDistribution[ratio] ?? 0,
    }));
  }

  return [];
};

const processCorrelationDataOverTime = (data) => {
  const dates = Object.keys(data);
  if (dates.length === 0) return [];

  dates.sort((a, b) => new Date(a) - new Date(b));
  const latestDate = dates[dates.length - 1];
  const correlations = data[latestDate]?.correlations;

  const correlationData = [];

  if (correlations && typeof correlations === 'object') {
    Object.keys(correlations).forEach(key => {
      let pair = '';
      if (key === 'price_to_mileage_correlation') {
        pair = 'Price vs. Mileage';
      } else if (key === 'year_price_correlation') {
        pair = 'Year vs. Price';
      }
      correlationData.push({
        key,
        pair,
        correlation: correlations[key],
      });
    });
  }

  return correlationData;
};

// Live Processing Functions

const processMileageDistributionLive = (data) => {
  const mileageBucketCounts = data?.inventory_statistics?.mileage_bucket_counts;

  if (mileageBucketCounts && typeof mileageBucketCounts === 'object') {
    return Object.keys(mileageBucketCounts).map(bucket => ({
      bucket,
      count: mileageBucketCounts[bucket] ?? 0,
    }));
  }

  return [];
};

const processTimeInInventoryDataLive = (data) => {
  const timeInInventoryCounts = data?.inventory_statistics?.time_in_inventory_bucket_counts;

  if (timeInInventoryCounts && typeof timeInInventoryCounts === 'object') {
    return Object.keys(timeInInventoryCounts).map(bucket => ({
      bucket,
      count: timeInInventoryCounts[bucket] ?? 0,
    }));
  }

  return [];
};

const processConditionDistributionLive = (data) => {
  const conditionDistribution = data?.condition_distribution;

  if (conditionDistribution && typeof conditionDistribution === 'object') {
    return Object.keys(conditionDistribution).map(condition => ({
      name: condition.charAt(0).toUpperCase() + condition.slice(1),
      value: conditionDistribution[condition] ?? 0,
    }));
  }

  return [];
};

const processAveragePricePerModelLive = (data) => {
  const averagePricePerModel = data?.make_model_insights?.average_price_per_model;

  if (averagePricePerModel && Array.isArray(averagePricePerModel)) {
    return averagePricePerModel.map(item => ({
      make_model: `${item.Make} ${item.Model}`,
      average_price: item.Price ?? 0,
    }));
  }

  return [];
};

const processAveragePricePerYearLive = (data) => {
  const averagePricePerYear = data?.average_price_per_year;

  if (averagePricePerYear && typeof averagePricePerYear === 'object') {
    return Object.keys(averagePricePerYear).map(year => ({
      year,
      average_price: averagePricePerYear[year] ?? 0,
    }));
  }

  return [];
};

const processVehicleAgeDistributionLive = (data) => {
  const ageDistribution = data?.vehicle_age_statistics?.age_distribution;

  if (ageDistribution && typeof ageDistribution === 'object') {
    return Object.keys(ageDistribution).map(age => ({
      age,
      count: ageDistribution[age] ?? 0,
    }));
  }

  return [];
};

const processTopMakesModelsLive = (data) => {
  const topMakesModels = data?.make_model_insights?.top_makes_models;

  if (topMakesModels && Array.isArray(topMakesModels)) {
    return topMakesModels.map(item => ({
      make_model: `${item.Make} ${item.Model}`,
      count: item.count ?? 0,
    }));
  }

  return [];
};

const processAverageTimeInInventoryPerModelLive = (data) => {
  const avgTimePerModel = data?.average_time_in_inventory_per_make_model;

  if (avgTimePerModel && Array.isArray(avgTimePerModel)) {
    return avgTimePerModel.map(item => ({
      make_model: `${item.Make} ${item.Model}`,
      average_time: item.time_in_inventory ?? 0,
    }));
  }

  return [];
};

const processInventoryValueDataLive = (data) => {
  const inventoryValue = data?.inventory_value;

  if (inventoryValue && typeof inventoryValue === 'object') {
    return {
      total_inventory_value: inventoryValue.total_inventory_value ?? 0,
      average_vehicle_value: inventoryValue.average_vehicle_value ?? 0,
    };
  }

  return null;
};

const processThroughputDataLive = (data) => {
  const throughput = data?.throughput;

  if (throughput) {
    return [{
      date: 'Live',
      added: throughput.cars_added_to_inventory ?? 0,
      removed: throughput.cars_removed_from_inventory ?? 0,
    }];
  }

  return [];
};

const processPriceChangesDataLive = (data) => {
  const priceChanges = data?.price_changes?.price_changes;

  if (priceChanges && Array.isArray(priceChanges)) {
    // Create buckets for price changes
    const buckets = {};

    priceChanges.forEach(change => {
      const priceChange = change.price_change ?? 0;
      let bucket = '';

      if (priceChange < -5000) {
        bucket = '< -$5,000';
      } else if (priceChange < -1000) {
        bucket = '-$5,000 to -$1,000';
      } else if (priceChange < 0) {
        bucket = '-$1,000 to $0';
      } else if (priceChange === 0) {
        bucket = '$0';
      } else if (priceChange <= 1000) {
        bucket = '$0 to $1,000';
      } else if (priceChange <= 5000) {
        bucket = '$1,000 to $5,000';
      } else {
        bucket = '> $5,000';
      }

      buckets[bucket] = (buckets[bucket] || 0) + 1;
    });

    return Object.keys(buckets).map(bucket => ({
      price_change_bucket: bucket,
      count: buckets[bucket],
    }));
  }

  return [];
};

const processDataCompletenessLive = (data) => {
  const dataCompleteness = data?.data_completeness;

  if (dataCompleteness && typeof dataCompleteness === 'object') {
    return Object.keys(dataCompleteness).map(field => ({
      field,
      completeness: dataCompleteness[field] ?? 0,
    }));
  }

  return [];
};

const processColorDistributionLive = (data) => {
  const colorDistribution = data?.color_distribution;

  if (colorDistribution && typeof colorDistribution === 'object') {
    return Object.keys(colorDistribution).map(color => ({
      name: color,
      value: colorDistribution[color] ?? 0,
    }));
  }

  return [];
};

const processPriceMileageRatioLive = (data) => {
  const ratioDistribution = data?.price_mileage_ratio?.distribution;

  if (ratioDistribution && typeof ratioDistribution === 'object') {
    return Object.keys(ratioDistribution).map(ratio => ({
      ratio_bucket: ratio,
      count: ratioDistribution[ratio] ?? 0,
    }));
  }

  return [];
};

const processCorrelationDataLive = (data) => {
  const correlations = data?.correlations;

  const correlationData = [];

  if (correlations && typeof correlations === 'object') {
    Object.keys(correlations).forEach(key => {
      let pair = '';
      if (key === 'price_to_mileage_correlation') {
        pair = 'Price vs. Mileage';
      } else if (key === 'year_price_correlation') {
        pair = 'Year vs. Price';
      }
      correlationData.push({
        key,
        pair,
        correlation: correlations[key],
      });
    });
  }

  return correlationData;
};

// Export the Dashboard component
export default Dashboard;

// src/utils.js



/**
 * Parses a date string in 'MM-DD-YYYY' or 'YYYY-MM-DD' format and returns a Date object.
 * @param {string | Date} dateInput - The date string or Date object.
 * @returns {Date} - Parsed Date object.
 */
export function parseDate(dateInput) {
  if (typeof dateInput === 'string') {
    const parts = dateInput.split('-');
    if (parts.length !== 3) {
      throw new Error('Invalid date string format. Expected "YYYY-MM-DD" or "MM-DD-YYYY".');
    }
    let [part1, part2, part3] = parts;

    // Determine format based on the first part (year likely to be 4 digits)
    if (part1.length === 4) {
      // 'YYYY-MM-DD'
      return new Date(dateInput);
    } else {
      // Assume 'MM-DD-YYYY'
      return new Date(`${part3}-${part1}-${part2}`);
    }
  } else if (dateInput instanceof Date) {
    return dateInput;
  } else {
    throw new Error('Invalid date input. Expected a Date object or a string in "YYYY-MM-DD" or "MM-DD-YYYY" format.');
  }
}

/**
 * Formats a Date object or date string to 'YYYY-MM-DD'.
 * @param {string | Date} dateInput - The date string or Date object.
 * @returns {string} - Formatted date string in 'YYYY-MM-DD'.
 */
export function formatDateYYYYMMDD(dateInput) {
  const date = parseDate(dateInput);

  if (isNaN(date.getTime())) {
    throw new Error('Invalid date input. Expected a valid Date object or a string in "YYYY-MM-DD" or "MM-DD-YYYY" format.');
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

/**
 * Formats a date string or Date object to 'MM-DD-YYYY'.
 * @param {string | Date} dateInput - The date string in 'YYYY-MM-DD' format or a Date object.
 * @returns {string} - Formatted date string in 'MM-DD-YYYY'.
 */
export const formatDateMMDDYYYY = (dateInput) => {
  let year, month, day;

  if (typeof dateInput === 'string') {
    // Handle date string in 'YYYY-MM-DD' or 'MM-DD-YYYY' format
    const parts = dateInput.split('-');
    if (parts.length !== 3) {
      throw new Error('Invalid date string format. Expected "YYYY-MM-DD" or "MM-DD-YYYY".');
    }
    if (parts[0].length === 4) {
      // 'YYYY-MM-DD'
      [year, month, day] = parts;
    } else {
      // 'MM-DD-YYYY'
      [month, day, year] = parts;
    }
  } else if (dateInput instanceof Date) {
    // Handle Date object
    const d = dateInput;
    year = d.getFullYear().toString();
    month = (d.getMonth() + 1).toString().padStart(2, '0');
    day = d.getDate().toString().padStart(2, '0');
  } else {
    throw new Error('Invalid date input. Expected a Date object or a string in "YYYY-MM-DD" or "MM-DD-YYYY" format.');
  }

  return `${month}-${day}-${year}`;
};

/**
 * Formats a metric value based on its type.
 * @param {number} value - The metric value to format.
 * @param {string} metric - The metric type ('average_price', 'average_inventory', 'sales_trends').
 * @returns {string} - The formatted metric value.
 */
export function formatMetricValue(value, metric) {
  if (value === undefined || value === null || isNaN(value)) {
    return 'N/A';
  }

  if (
    metric === 'average_price' ||
    metric === 'average_vehicle_value' ||
    metric === 'total_inventory_value'
  ) {
    // Use Intl.NumberFormat for currency formatting
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(Math.round(value));
  } else if (
    metric === 'total_inventory' ||
    metric === 'cars_sold' ||
    metric === 'long_term_inventory'
  ) {
    return Math.round(value).toLocaleString();
  } else if (
    metric === 'average_age' ||
    metric.startsWith('time_in_inventory')
  ) {
    return value.toFixed(2);
  } else if (metric === 'turnover_rate') {
    return (value * 100).toFixed(2);
  } else {
    return value.toString();
  }
}


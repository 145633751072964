// src/App.js
import React, { useEffect, useState } from 'react';
import './App.css';
import logo from './images/mm-logo-white.png'; // Ensure this path is correct
import LoginScreen from './LoginScreen';
import FeedbackComponent from './FeedbackComponent';
import Dashboard from './Dashboard';
import AdminPanel from './AdminPanel'; // Import AdminPanel
import MapComponent from './MapComponent'; // Import MapComponent
import Data from './Data'; // Import Data component

// Import FontAwesome Components and Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTachometerAlt,
  faDatabase, // Raw Data icon
  faMapMarkedAlt,
  faBalanceScale,
  faComments,
  faSignOutAlt,
  faUserShield, // Admin icon
  faTrashAlt, // Import the trash icon
} from '@fortawesome/free-solid-svg-icons';

import axios from 'axios'; // Import axios for HTTP requests

// Import AuthContext
import { useAuth } from './AuthContext'; // Ensure correct import path and casing

function App() {
  // Destructure values from AuthContext
  const { user, signInWithGoogle, signOut, role, loading } = useAuth();

  const [activeTab, setActiveTab] = useState('Dashboard');
  const [selectedDealership, setSelectedDealership] = useState(''); // Holds dealership name
  const [dealerships, setDealerships] = useState([]); // State for dealership list
  const [dealershipsLoading, setDealershipsLoading] = useState(false); // Loading state for dealerships
  const [dealershipsError, setDealershipsError] = useState(null); // Error state for dealerships

  // Define the tabs where the dealership selector should be hidden
  const HIDE_DEALERSHIP_SELECTOR = ['Admin', 'Feedback', 'Compare'];

  // Fetch Dealerships on component mount
  useEffect(() => {
    fetchDealerships();
  }, []);

  const fetchDealerships = async () => {
    setDealershipsLoading(true);
    setDealershipsError(null);

    try {
      // Retrieve cached data and date from localStorage
      const cachedData = localStorage.getItem('configData');
      const cachedDate = localStorage.getItem('configDate');
      const today = new Date().toDateString(); // e.g., "Sun Oct 08 2023"

      if (cachedData && cachedDate === today) {
        // Use cached data
        const data = JSON.parse(cachedData);
        const dealershipNames = data.dealerships.map((d) => d.name);
        setDealerships(dealershipNames);
      } else {
        // Fetch new data and update cache
        const response = await axios.get('https://dealer-savedata.s3.amazonaws.com/config.json');
        const dealershipNames = response.data.dealerships.map((d) => d.name);
        setDealerships(dealershipNames);

        // Store new data and current date in localStorage
        localStorage.setItem('configData', JSON.stringify(response.data));
        localStorage.setItem('configDate', today);
      }
    } catch (error) {
      console.error('Error fetching dealerships:', error);
      // Fallback if config.json is not accessible
      const fallbackDealershipNames = ['BMW_of_San_Diego', 'Weatherford_BMW', 'BMW_Seattle'];
      setDealerships(fallbackDealershipNames);
      setDealershipsError('Failed to load dealerships. Using fallback list.');
    } finally {
      setDealershipsLoading(false);
    }
  };

  const handleClearCache = () => {
    localStorage.removeItem('configData');
    localStorage.removeItem('configDate');
    fetchDealerships(); // Optionally re-fetch data after clearing cache
  };

  // Optional: Set default selected dealership when dealerships are loaded
  useEffect(() => {
    if (dealerships.length > 0 && !selectedDealership) {
      setSelectedDealership(dealerships[0]); // Set the first dealership as default
    }
  }, [dealerships, selectedDealership]);

  if (loading) {
    // Optionally, show a loading indicator while authentication state is being determined
    return <div>Loading...</div>;
  }

  if (!user) {
    // Show the login screen if the user is not authenticated
    return <LoginScreen signInWithGoogle={signInWithGoogle} />;
  }

  return (
    <div className="App">
      <div className="sidebar">
        <img src={logo} alt="Logo" className="logo" />
        {user && (
          <div className="user-info">
          {user.user_metadata.full_name && (
            <>
              Welcome, {user.user_metadata.full_name}
              <br />
            </>
          )}
          <button className="logout-button" onClick={signOut}>
            <FontAwesomeIcon icon={faSignOutAlt} /> Log Out
          </button>
        </div>
        )}

        <div className="sidebar-content">
          {/* Sidebar Tabs */}
          <div className="tabs">
            {/* Dashboard Tab */}
            <button
              className={activeTab === 'Dashboard' ? 'tab active' : 'tab'}
              onClick={() => setActiveTab('Dashboard')}
              aria-label="Dashboard Tab"
            >
              <FontAwesomeIcon icon={faTachometerAlt} style={{ marginRight: '10px' }} />
              Dashboard
            </button>

            {/* Raw Data Tab */}
            <button
              className={activeTab === 'Data' ? 'tab active' : 'tab'}
              onClick={() => setActiveTab('Data')}
              aria-label="Raw Data Tab"
            >
              <FontAwesomeIcon icon={faDatabase} style={{ marginRight: '10px' }} />
              Raw Data
            </button>

            {/* Map Tab */}
            <button
              className={activeTab === 'Map' ? 'tab active' : 'tab'}
              onClick={() => setActiveTab('Map')}
              aria-label="Map Tab"
            >
              <FontAwesomeIcon icon={faMapMarkedAlt} style={{ marginRight: '10px' }} />
              Map
            </button>

            {/* Compare Tab */}
            <button
              className={activeTab === 'Compare' ? 'tab active' : 'tab'}
              onClick={() => setActiveTab('Compare')}
              aria-label="Compare Tab"
            >
              <FontAwesomeIcon icon={faBalanceScale} style={{ marginRight: '10px' }} />
              Compare
            </button>

            {/* Feedback Tab */}
            <button
              className={activeTab === 'Feedback' ? 'tab active' : 'tab'}
              onClick={() => setActiveTab('Feedback')}
              aria-label="Feedback Tab"
            >
              <FontAwesomeIcon icon={faComments} style={{ marginRight: '10px' }} />
              Feedback
            </button>

            {/* Conditionally render the Admin tab */}
            {role === 'admin' && (
              <>
                <button
                  className={activeTab === 'Admin' ? 'tab active' : 'tab'}
                  onClick={() => setActiveTab('Admin')}
                  aria-label="Admin Tab"
                >
                  <FontAwesomeIcon icon={faUserShield} style={{ marginRight: '10px' }} />
                  Admin
                </button>

                {/* Clear Cache Button */}
                <button
                  className="tab clear-cache-button"
                  onClick={handleClearCache}
                  aria-label="Clear Cache Button"
                >
                  <FontAwesomeIcon icon={faTrashAlt} style={{ marginRight: '10px' }} />
                  Clear Cache
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="main-content-container">
        {/* Conditionally render the Dealership Selector */}
        {!HIDE_DEALERSHIP_SELECTOR.includes(activeTab) && (
          <div className="dealership-selector">
            <h3>Select Dealership</h3>
            {dealershipsLoading ? (
              <p>Loading dealerships...</p>
            ) : dealershipsError ? (
              <p style={{ color: 'red' }}>{dealershipsError}</p>
            ) : (
              <select
                value={selectedDealership}
                onChange={(e) => setSelectedDealership(e.target.value)}
              >
                <option value="" disabled>
                  Select a dealership
                </option>
                {dealerships.map((dealership) => (
                  <option key={dealership} value={dealership}>
                    {dealership}
                  </option>
                ))}
              </select>
            )}
          </div>
        )}
        {/* Main Content */}
        <div className="main-content">
          {/* Render content based on the active tab */}
          {activeTab === 'Dashboard' && (
            <Dashboard
              selectedDealership={selectedDealership}
              setSelectedDealership={setSelectedDealership} // Pass setter if needed
            />
          )}
          {activeTab === 'Data' && <Data selectedDealership={selectedDealership} />}
          {activeTab === 'Map' && (
            <MapComponent
              selectedDealership={selectedDealership}
              setSelectedDealership={setSelectedDealership}
            />
          )}
          {activeTab === 'Compare' && (
            <div>
              <h1>Compare</h1>
              <p>This is the Compare tab content.</p>
            </div>
          )}
          {activeTab === 'Feedback' && <FeedbackComponent />}
          {activeTab === 'Admin' && role === 'admin' && <AdminPanel />}
        </div>
      </div>
    </div>
  );
}

/* Styles (Moved to App.css) */

export default App;

// src/MapComponent.js

import React, { useEffect, useRef, useState, useCallback } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import axios from 'axios';
import {
  Select,
  Slider,
  Spin,
  message,
  Card,
  Statistic,
  Row,
  Col,
  Tooltip,
  Space,
  Radio,
  Switch,
} from 'antd';
import {
  DollarOutlined,
  AppstoreOutlined,
  ShoppingCartOutlined,
  FilterOutlined,
  LineChartOutlined,
  ClockCircleOutlined,
  ReloadOutlined,
  CalendarOutlined,
} from '@ant-design/icons';
import 'antd/dist/reset.css';
import './MapComponent.css';
import { formatMetricValue } from './utils';
import bmwMLogo from './images/bmw-m-logo.png'; // Ensure this path is correct

// Keep your Mapbox access token
mapboxgl.accessToken =
  process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ||
  'pk.eyJ1IjoibWxvaGllciIsImEiOiJjbTFoMG1lMGkwOXZ4MmtweDNtZ2d6aWttIn0.mt7abE-TVOrcWrSxSdEe9A';

function MapComponent({ selectedDealership, setSelectedDealership }) {
  const mapContainer = useRef(null);
  const map = useRef(null);

  const [dealerships, setDealerships] = useState([]);
  const [filteredDealerships, setFilteredDealerships] = useState([]);
  const [statsData, setStatsData] = useState({});
  const [loading, setLoading] = useState(true);
  const [filterLoading, setFilterLoading] = useState(false);
  const [error, setError] = useState(false);

  // Filters state
  const [priceRange, setPriceRange] = useState([0, 100000]);
  const [inventoryRange, setInventoryRange] = useState([0, 500]);
  const [averageVehicleValueRange, setAverageVehicleValueRange] = useState([
    0,
    1000000,
  ]);
  const [totalInventoryValueRange, setTotalInventoryValueRange] = useState([
    0,
    100000000,
  ]);
  const [winningMetric, setWinningMetric] = useState('average_price');

  // New filters state
  const [dataFilter, setDataFilter] = useState('all'); // 'all' or 'm_cars'
  const [inventoryType, setInventoryType] = useState('combined'); // 'combined', 'new', 'used'

  // Fetch dealerships from config.json
  useEffect(() => {
    const fetchDealerships = async () => {
      try {
        const response = await axios.get(
          'https://dealer-savedata.s3.amazonaws.com/config.json'
        );
        setDealerships(response.data.dealerships);
        setFilteredDealerships(response.data.dealerships);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching dealerships:', err);
        setError(true);
        setLoading(false);
      }
    };
    fetchDealerships();
  }, []);

  // Fetch statistics for all dealerships based on filters
  useEffect(() => {
    const fetchAllStatistics = async () => {
      if (dealerships.length === 0) return;
      setFilterLoading(true);
      try {
        const statsPromises = dealerships.map(async (dealership) => {
          const response = await axios.get(`/.netlify/functions/fetchStatsData`, {
            params: {
              dealership: dealership.name,
              inventoryType: inventoryType,
              dataFilter: dataFilter,
              mode: 'live', // Explicitly set mode to 'live'
            },
          });
          return { [dealership.name]: response.data };
        });
        const allStats = await Promise.all(statsPromises);
        const combinedStats = Object.assign({}, ...allStats);
        setStatsData(combinedStats);
      } catch (error) {
        console.error('Error fetching statistics:', error);
        message.error('Error fetching statistics data.');
      } finally {
        setFilterLoading(false);
      }
    };
    fetchAllStatistics();
  }, [dealerships, dataFilter, inventoryType]);

  // Apply filters to dealerships
  const applyFilters = () => {
    if (dealerships.length === 0 || Object.keys(statsData).length === 0) return;

    setFilterLoading(true);
    const filtered = dealerships.filter((dealership) => {
      const stats = statsData[dealership.name];
      if (!stats) return false;

      const avgPrice = stats.inventory_statistics?.price_mean || 0;
      const totalInventory = stats.throughput?.total_inventory || 0;
      const averageVehicleValue = stats.inventory_value?.average_vehicle_value || 0;
      const totalInventoryValue = stats.inventory_value?.total_inventory_value || 0;

      return (
        avgPrice >= priceRange[0] &&
        avgPrice <= priceRange[1] &&
        totalInventory >= inventoryRange[0] &&
        totalInventory <= inventoryRange[1] &&
        averageVehicleValue >= averageVehicleValueRange[0] &&
        averageVehicleValue <= averageVehicleValueRange[1] &&
        totalInventoryValue >= totalInventoryValueRange[0] &&
        totalInventoryValue <= totalInventoryValueRange[1]
      );
    });
    setFilteredDealerships(filtered);
    setFilterLoading(false);
  };

  // Update filtered dealerships when filters change
  useEffect(() => {
    applyFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    priceRange,
    inventoryRange,
    averageVehicleValueRange,
    totalInventoryValueRange,
    statsData,
  ]);

  // Memoize getColorForMetric using useCallback
  const getColorForMetric = useCallback(
    (value, metric) => {
      // Define color scales (blue to red)
      const colors = [
        '#0000FF', // Blue
        '#3333FF',
        '#6666FF',
        '#9999FF',
        '#CCCCFF',
        '#FFCCCC',
        '#FF9999',
        '#FF6666',
        '#FF3333',
        '#FF0000', // Red
      ];
      let thresholds = [];

      // Set thresholds based on metric
      const sortedValues = Object.values(statsData)
        .map((stats) => {
          if (metric === 'average_price') {
            return stats?.inventory_statistics?.price_mean || 0;
          } else if (metric === 'total_inventory') {
            return stats?.throughput?.total_inventory || 0;
          } else if (metric === 'cars_sold') {
            return stats?.throughput?.cars_removed_from_inventory || 0;
          } else if (metric === 'average_vehicle_value') {
            return stats?.inventory_value?.average_vehicle_value || 0;
          } else if (metric === 'total_inventory_value') {
            return stats?.inventory_value?.total_inventory_value || 0;
          } else if (metric === 'average_age') {
            return stats?.vehicle_age_statistics?.average_age || 0;
          } else if (metric === 'turnover_rate') {
            return stats?.turnover_rate?.turnover_rate || 0;
          }
          return 0;
        })
        .sort((a, b) => a - b);

      // Create dynamic thresholds based on data distribution
      thresholds = [];
      const numColors = colors.length;
      for (let i = 1; i < numColors; i++) {
        const index = Math.floor((i / numColors) * sortedValues.length);
        thresholds.push(sortedValues[index]);
      }

      for (let i = 0; i < thresholds.length; i++) {
        if (value <= thresholds[i]) {
          return colors[i];
        }
      }
      return colors[colors.length - 1];
    },
    [statsData]
  );

  // Add markers for dealerships and handle selection on click
  useEffect(() => {
    if (!map.current || filteredDealerships.length === 0) return;

    // Remove existing markers
    if (map.current.markers) {
      map.current.markers.forEach((marker) => marker.remove());
    } else {
      map.current.markers = []; // Initialize markers array if undefined
    }

    map.current.markers = [];

    // Determine the range of the winning metric to scale marker sizes
    const metricValues = filteredDealerships.map((dealership) => {
      const stats = statsData[dealership.name];
      let value = 0;
      if (winningMetric === 'average_price') {
        value = stats?.inventory_statistics?.price_mean || 0;
      } else if (winningMetric === 'total_inventory') {
        value = stats?.throughput?.total_inventory || 0;
      } else if (winningMetric === 'cars_sold') {
        value = stats?.throughput?.cars_removed_from_inventory || 0;
      } else if (winningMetric === 'average_vehicle_value') {
        value = stats?.inventory_value?.average_vehicle_value || 0;
      } else if (winningMetric === 'total_inventory_value') {
        value = stats?.inventory_value?.total_inventory_value || 0;
      } else if (winningMetric === 'average_age') {
        value = stats?.vehicle_age_statistics?.average_age || 0;
      } else if (winningMetric === 'turnover_rate') {
        value = stats?.turnover_rate?.turnover_rate || 0;
      }
      return value;
    });
    const minMetric = Math.min(...metricValues);
    const maxMetric = Math.max(...metricValues);

    // Define minimum and maximum marker sizes
    const minSize = 30; // Adjusted size
    const maxSize = 60;

    // Function to scale metric values to marker sizes
    const scaleSize = (value) => {
      if (maxMetric === minMetric) return (minSize + maxSize) / 2;
      return (
        minSize +
        ((value - minMetric) / (maxMetric - minMetric)) * (maxSize - minSize)
      );
    };

    filteredDealerships.forEach((dealership) => {
      const [lng, lat] = dealership.position;

      const stats = statsData[dealership.name];
      let metricValue = 0;
      if (winningMetric === 'average_price') {
        metricValue = stats?.inventory_statistics?.price_mean || 0;
      } else if (winningMetric === 'total_inventory') {
        metricValue = stats?.throughput?.total_inventory || 0;
      } else if (winningMetric === 'cars_sold') {
        metricValue = stats?.throughput?.cars_removed_from_inventory || 0;
      } else if (winningMetric === 'average_vehicle_value') {
        metricValue = stats?.inventory_value?.average_vehicle_value || 0;
      } else if (winningMetric === 'total_inventory_value') {
        metricValue = stats?.inventory_value?.total_inventory_value || 0;
      } else if (winningMetric === 'average_age') {
        metricValue = stats?.vehicle_age_statistics?.average_age || 0;
      } else if (winningMetric === 'turnover_rate') {
        metricValue = stats?.turnover_rate?.turnover_rate || 0;
      }

      // Determine marker color based on metricValue
      const color = getColorForMetric(metricValue, winningMetric);

      // Determine marker size based on metricValue
      const size = scaleSize(metricValue);

      // Format the metric value
      const formattedValue = formatMetricValue(metricValue, winningMetric);

      // Determine if the metric is currency
      const isCurrency =
        winningMetric === 'average_price' ||
        winningMetric === 'average_vehicle_value' ||
        winningMetric === 'total_inventory_value';

      // Create a custom marker element with the metric value
      const el = document.createElement('div');
      el.className = `marker ${isCurrency ? 'currency' : 'numeric'}`;
      el.style.backgroundColor = color;
      el.style.width = `${size}px`;
      el.style.height = `${size}px`;
      el.style.borderRadius = '50%';
      el.style.cursor = 'pointer';
      el.style.border = '2px solid white';
      el.style.display = 'flex';
      el.style.justifyContent = 'center';
      el.style.alignItems = 'center';
      el.style.pointerEvents = 'auto';
      el.style.color = '#fff';
      el.style.fontSize = '12px';
      el.style.fontWeight = 'bold';
      el.style.textAlign = 'center';
      el.style.lineHeight = '1.2';

      // Set the formatted metric value inside the marker
      el.innerText = metricValue !== 0 ? formattedValue : '';

      // Create popup content without the button
      const popupNode = document.createElement('div');
      popupNode.innerHTML = `
        <div style="
          font-family: Arial, sans-serif; 
          color: #fff; 
          background-color: rgba(0, 0, 0, 0.8); 
          padding: 10px; 
          border-radius: 4px;">
          <h3 style="font-size: 1.2em; margin-bottom: 8px;">${dealership.name}</h3>
          <p>Click to view statistics</p>
        </div>
      `;

      const popup = new mapboxgl.Popup({ offset: 25 }).setDOMContent(popupNode);

      const marker = new mapboxgl.Marker(el)
        .setLngLat([lng, lat])
        .setPopup(popup)
        .addTo(map.current);

      // Store the popup in the marker object for later reference
      marker.popup = popup;

      map.current.markers.push(marker);

      // Attach event listener to select dealership on marker click
      marker.getElement().addEventListener('click', () => {
        setSelectedDealership(dealership.name);
      });
    });
  }, [
    filteredDealerships,
    setSelectedDealership,
    statsData,
    winningMetric,
    dataFilter,
    inventoryType,
    getColorForMetric, // Now memoized, safe to include
  ]);

  // Initialize the Mapbox map
  useEffect(() => {
    if (!mapContainer.current || map.current) return;

    if (
      filteredDealerships.length > 0 &&
      filteredDealerships[0].position.length === 2
    ) {
      const [lng, lat] = filteredDealerships[0].position;
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/dark-v10',
        center: [lng, lat],
        zoom: 4,
      });

      map.current.addControl(new mapboxgl.NavigationControl(), 'top-right');

      // Initialize markers array
      map.current.markers = [];
    }
  }, [filteredDealerships]);

  // Center map and open popup for the selected dealership
  useEffect(() => {
    if (
      selectedDealership &&
      map.current &&
      map.current.markers &&
      map.current.markers.length > 0
    ) {
      const dealershipObj = dealerships.find(
        (d) => d.name === selectedDealership
      );
      if (dealershipObj) {
        const marker = map.current.markers.find((m) => {
          const lngLat = m.getLngLat();
          const [lng, lat] = dealershipObj.position;
          return lngLat.lng === lng && lngLat.lat === lat;
        });

        if (marker) {
          // Center the map on the selected dealership
          map.current.flyTo({
            center: marker.getLngLat(),
            essential: true,
            zoom: 6,
          });

          // Open the popup associated with the marker
          marker.popup.addTo(map.current);
        }
      }
    }
  }, [selectedDealership, dealerships]);

  // Generate the statistics display
  const generateStatsDisplay = () => {
    if (!statsData || !selectedDealership) return null;

    const stats = statsData[selectedDealership];
    if (!stats)
      return (
        <div className="no-data">
          <p>Statistics data is unavailable for {selectedDealership}.</p>
        </div>
      );

    // Extract relevant statistics, handling missing data
    const avgPrice = stats.inventory_statistics?.price_mean;
    const totalInventory = stats.throughput?.total_inventory;
    const carsSold = stats.throughput?.cars_removed_from_inventory;
    const averageVehicleValue = stats.inventory_value?.average_vehicle_value;
    const totalInventoryValue = stats.inventory_value?.total_inventory_value;
    const averageAge = stats.vehicle_age_statistics?.average_age;
    const turnoverRate = stats.turnover_rate?.turnover_rate;
    const timeInInventoryMean = stats.time_in_inventory_mean;
    const timeInInventoryMedian = stats.time_in_inventory_median;
    const longTermInventory = stats.long_term_inventory_over_30_days;

    // Format the statistics values, defaulting to 'N/A' if data is missing
    const formattedAvgPrice = formatMetricValue(avgPrice, 'average_price');
    const formattedTotalInventory = formatMetricValue(
      totalInventory,
      'total_inventory'
    );
    const formattedCarsSold = formatMetricValue(carsSold, 'cars_sold');
    const formattedAverageVehicleValue = formatMetricValue(
      averageVehicleValue,
      'average_vehicle_value'
    );
    const formattedTotalInventoryValue = formatMetricValue(
      totalInventoryValue,
      'total_inventory_value'
    );
    const formattedAverageAge = formatMetricValue(averageAge, 'average_age');
    const formattedTurnoverRate = formatMetricValue(
      turnoverRate,
      'turnover_rate'
    );
    const formattedTimeInInventoryMean = formatMetricValue(
      timeInInventoryMean,
      'time_in_inventory_mean'
    );
    const formattedTimeInInventoryMedian = formatMetricValue(
      timeInInventoryMedian,
      'time_in_inventory_median'
    );
    const formattedLongTermInventory = formatMetricValue(
      longTermInventory,
      'long_term_inventory'
    );

    return (
      <Card bordered={false} className="stats-card">
        <Row gutter={[16, 16]}>
          {/* Average Price */}
          <Col xs={24} sm={12} md={6}>
            <Card className="stat-box" hoverable>
              <Statistic
                title="Average Price"
                value={formattedAvgPrice}
                precision={0}
                valueStyle={{ color: '#3f8600' }}
                prefix={<DollarOutlined />}
              />
            </Card>
          </Col>

          {/* Average Vehicle Value */}
          <Col xs={24} sm={12} md={6}>
            <Card className="stat-box" hoverable>
              <Statistic
                title="Avg Vehicle Value"
                value={formattedAverageVehicleValue}
                precision={0}
                valueStyle={{ color: '#fa8c16' }}
                prefix={<DollarOutlined />}
              />
            </Card>
          </Col>

          {/* Total Inventory Value */}
          <Col xs={24} sm={12} md={6}>
            <Card className="stat-box" hoverable>
              <Statistic
                title="Total Inventory Value"
                value={formattedTotalInventoryValue}
                precision={0}
                valueStyle={{ color: '#722ed1' }}
                prefix={<DollarOutlined />}
              />
            </Card>
          </Col>

          {/* Total Inventory */}
          <Col xs={24} sm={12} md={6}>
            <Card className="stat-box" hoverable>
              <Statistic
                title="Total Inventory"
                value={formattedTotalInventory}
                precision={0}
                valueStyle={{ color: '#cf1322' }}
                prefix={<AppstoreOutlined />}
              />
            </Card>
          </Col>

          {/* Cars Sold */}
          <Col xs={24} sm={12} md={6}>
            <Card className="stat-box" hoverable>
              <Statistic
                title="Cars Sold"
                value={formattedCarsSold}
                precision={0}
                valueStyle={{ color: '#1890ff' }}
                prefix={<ShoppingCartOutlined />}
              />
            </Card>
          </Col>

          {/* Average Age (Days) */}
          <Col xs={24} sm={12} md={6}>
            <Card className="stat-box" hoverable>
              <Statistic
                title="Average Age (Days)"
                value={formattedAverageAge}
                precision={2}
                valueStyle={{ color: '#13c2c2' }}
                prefix={<ClockCircleOutlined />}
              />
            </Card>
          </Col>

          {/* Turnover Rate */}
          <Col xs={24} sm={12} md={6}>
            <Card className="stat-box" hoverable>
              <Statistic
                title="Turnover Rate"
                value={formattedTurnoverRate}
                precision={2}
                valueStyle={{ color: '#eb2f96' }}
                prefix={<ReloadOutlined />}
                suffix="%"
              />
            </Card>
          </Col>

          {/* Time in Inventory Mean */}
          <Col xs={24} sm={12} md={6}>
            <Card className="stat-box" hoverable>
              <Statistic
                title="Avg Time in Inventory (Days)"
                value={formattedTimeInInventoryMean}
                precision={2}
                valueStyle={{ color: '#7cb305' }}
                prefix={<CalendarOutlined />}
              />
            </Card>
          </Col>

          {/* Time in Inventory Median */}
          <Col xs={24} sm={12} md={6}>
            <Card className="stat-box" hoverable>
              <Statistic
                title="Median Time in Inventory (Days)"
                value={formattedTimeInInventoryMedian}
                precision={2}
                valueStyle={{ color: '#b37feb' }}
                prefix={<CalendarOutlined />}
              />
            </Card>
          </Col>

          {/* Long Term Inventory Over 30 Days */}
          <Col xs={24} sm={12} md={6}>
            <Card className="stat-box" hoverable>
              <Statistic
                title="Long Term Inventory (>30 Days)"
                value={formattedLongTermInventory}
                precision={0}
                valueStyle={{ color: '#d4b106' }}
                prefix={<AppstoreOutlined />}
              />
            </Card>
          </Col>
        </Row>
      </Card>
    );
  };

  // Cleanup markers and map on component unmount
  useEffect(() => {
    return () => {
      if (map.current) {
        if (map.current.markers) {
          map.current.markers.forEach((marker) => marker.remove());
        }
        map.current.remove();
      }
    };
  }, []);

  // Handle map resize on window resize
  useEffect(() => {
    const handleResize = () => {
      if (map.current) {
        map.current.resize();
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="map-component">
      {/* Top Section: Filters Above Map */}
      <div className="top-filters">
        {/* Data Filter Toggle */}
        <div className="filter-group-inline">
          <Tooltip
            title={`Switch to ${dataFilter === 'all' ? 'BMW M Cars' : 'All Cars'}`}
          >
            <label>
              <span className="toggle-label">BMW M:</span>
            </label>
          </Tooltip>
          <Switch
            checked={dataFilter === 'm_cars'}
            onChange={() =>
              setDataFilter(dataFilter === 'all' ? 'm_cars' : 'all')
            }
            checkedChildren={
              <img
                src={bmwMLogo}
                alt="M Cars"
                className="bmw-m-logo-toggle"
              />
            }
            unCheckedChildren="All"
            className="custom-switch"
          />
        </div>

        {/* Inventory Type Toggle */}
        <div className="filter-group-inline">
          <Tooltip title="Select the inventory type">
            <label>
              <span className="toggle-label">Inventory Type:</span>
            </label>
          </Tooltip>
          <Radio.Group
            onChange={(e) => setInventoryType(e.target.value)}
            value={inventoryType}
            optionType="button"
            buttonStyle="solid"
            className="inventory-type-radio-group"
          >
            <Radio.Button value="combined">All</Radio.Button>
            <Radio.Button value="new">New</Radio.Button>
            <Radio.Button value="used">Used</Radio.Button>
          </Radio.Group>
        </div>
      </div>

      {/* Map and Side Filters */}
      <div className="top-section">
        {/* Filters */}
        <div className="filter-bar">
          <Card
            title={
              <span>
                <FilterOutlined style={{ marginRight: '8px' }} />
                Filters
              </span>
            }
            bordered={false}
            className="filter-card"
          >
            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
              {/* Average Price Filter */}
              <div className="filter-group">
                <Tooltip title="Filter dealerships based on average vehicle price">
                  <label>
                    <DollarOutlined style={{ marginRight: '8px' }} />
                    Average Price Range:
                  </label>
                </Tooltip>
                <Slider
                  range
                  min={0}
                  max={100000}
                  step={1000}
                  value={priceRange}
                  onChange={(value) => setPriceRange(value)}
                  tooltipVisible={false}
                />
                <div className="slider-value">
                  ${priceRange[0].toLocaleString()} - $
                  {priceRange[1].toLocaleString()}
                </div>
              </div>

              {/* Total Inventory Filter */}
              <div className="filter-group">
                <Tooltip title="Filter dealerships based on total inventory">
                  <label>
                    <AppstoreOutlined style={{ marginRight: '8px' }} />
                    Total Inventory Range:
                  </label>
                </Tooltip>
                <Slider
                  range
                  min={0}
                  max={500}
                  step={10}
                  value={inventoryRange}
                  onChange={(value) => setInventoryRange(value)}
                  tooltipVisible={false}
                />
                <div className="slider-value">
                  {inventoryRange[0]} - {inventoryRange[1]} units
                </div>
              </div>

              {/* Average Vehicle Value Filter */}
              <div className="filter-group">
                <Tooltip title="Filter dealerships based on average vehicle value">
                  <label>
                    <DollarOutlined style={{ marginRight: '8px' }} />
                    Avg Vehicle Value:
                  </label>
                </Tooltip>
                <Slider
                  range
                  min={0}
                  max={1000000}
                  step={10000}
                  value={averageVehicleValueRange}
                  onChange={(value) => setAverageVehicleValueRange(value)}
                  tooltipVisible={false}
                />
                <div className="slider-value">
                  ${averageVehicleValueRange[0].toLocaleString()} - $
                  {averageVehicleValueRange[1].toLocaleString()}
                </div>
              </div>

              {/* Total Inventory Value Filter */}
              <div className="filter-group">
                <Tooltip title="Filter dealerships based on total inventory value">
                  <label>
                    <DollarOutlined style={{ marginRight: '8px' }} />
                    Total Inventory Value:
                  </label>
                </Tooltip>
                <Slider
                  range
                  min={0}
                  max={100000000}
                  step={1000000}
                  value={totalInventoryValueRange}
                  onChange={(value) => setTotalInventoryValueRange(value)}
                  tooltipVisible={false}
                />
                <div className="slider-value">
                  ${totalInventoryValueRange[0].toLocaleString()} - $
                  {totalInventoryValueRange[1].toLocaleString()}
                </div>
              </div>

              {/* Winning Metric Selector */}
              <div className="filter-group">
                <Tooltip title="Select the metric to highlight dealerships on the map">
                  <label>
                    <LineChartOutlined style={{ marginRight: '8px' }} />
                    Winning Metric:
                  </label>
                </Tooltip>
                <Select
                  value={winningMetric}
                  onChange={(value) => setWinningMetric(value)}
                  style={{ width: '100%' }}
                  suffixIcon={<FilterOutlined />}
                >
                  <Select.Option value="average_price">
                    <DollarOutlined style={{ marginRight: '8px' }} />
                    Average Price
                  </Select.Option>
                  <Select.Option value="average_vehicle_value">
                    <DollarOutlined style={{ marginRight: '8px' }} />
                    Avg Vehicle Value
                  </Select.Option>
                  <Select.Option value="total_inventory_value">
                    <DollarOutlined style={{ marginRight: '8px' }} />
                    Total Inventory Value
                  </Select.Option>
                  <Select.Option value="total_inventory">
                    <AppstoreOutlined style={{ marginRight: '8px' }} />
                    Total Inventory
                  </Select.Option>
                  <Select.Option value="cars_sold">
                    <ShoppingCartOutlined style={{ marginRight: '8px' }} />
                    Cars Sold
                  </Select.Option>
                  <Select.Option value="average_age">
                    <ClockCircleOutlined style={{ marginRight: '8px' }} />
                    Average Age (Days)
                  </Select.Option>
                  <Select.Option value="turnover_rate">
                    <ReloadOutlined style={{ marginRight: '8px' }} />
                    Turnover Rate
                  </Select.Option>
                </Select>
              </div>

              {filterLoading && <Spin tip="Applying filters..." />}
            </Space>
          </Card>
        </div>

        {/* Map Container */}
        <div className="map-container" ref={mapContainer} />
      </div>

      {/* Bottom Section: Statistics Panel */}
      <div className="bottom-section">
        <div className="stats-panel">
          {selectedDealership ? (
            <>
              <h2>{selectedDealership} Statistics</h2>
              {generateStatsDisplay()}
            </>
          ) : (
            <div className="no-selection">
              <h3>
                Select a dealership on the map or from the dropdown to view
                statistics.
              </h3>
            </div>
          )}
        </div>
      </div>

      {/* Loading and Error States */}
      {loading && (
        <div className="loading">
          <Spin tip="Loading map data..." />
        </div>
      )}
      {error && (
        <div className="error">
          Error loading map data. Please try again later.
        </div>
      )}
    </div>
  );
}

export default MapComponent;
